import { useEffect, useState } from 'react';
import icAk from "../../../../../assets/imgs/gelsoftmx/replicas/ak_47_m_1.png"
 
const Cartforgetcomponent = (props) => {
    const {} = props;
    const [state, setState] = useState({});
    useEffect(() => {
    },[])
    return (
        <>
             <div className="flex flex-col mt-[-.5rem]">
                                    <div className="flex text-lg font-semibold">Se te olvida algo?</div>
                                    <div className="flex overflow-x-scroll gap-2">
                                        {   [
                                            {name: "Hidrogel normal", id:"1", price: 55},
                                            {name: "Hidrogel lechoso", id:"1", price: 100},
                                            {name: "Mira lateral", id:"1", price: 150},
                                            ].map((it)=>{
                                                return <div className="flex flex-col 
                                                border-2 border-neutral-400 px-1
                                                rounded min-w-[4.5rem] hover:border-neutral-600 duration-500 ease-in "> 
                                                    <div className="flex min-h-[3rem] items-center">
                                                        <img src={icAk} alt="" className=""/>
                                                    </div>
                                                    <div className="flex text-sm font-semibold">{it.name}</div>
                                                    {/* <div className="flex">{StringToMXN(it.price)}</div> */}
                                                </div>
                                            })

                                        }
                                    </div>
                                </div>
        </>
    );
}
 
export default Cartforgetcomponent;