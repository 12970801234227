
import React, { useState } from 'react'; 
import { Steps } from 'primereact/steps';

import IcFusil from '../../../../../assets/imgs/gelsoftmx/home/cards/fusil-de-asalto.png'
import IcShip from '../../../../../assets/imgs/envio.png'
import IcCustoms from '../../../../../assets/imgs/aduana.png'
import IcStore from '../../../../../assets/imgs/shopping-store.png'
export function StepperHomeTemplate() {
    const [activeIndex, setActiveIndex] = useState(0);

    const itemRenderer = (item, itemIndex) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

        return (
            <span
                className={`
                border-${item.fill ? 'cyan-600':'neutral-500'} border-2 p-2
                ${item.fill ? 'bg-cyan-700/90 ring-2':'bg-neutral-100'}
                relative
                inline-flex justify-center 
                items-center rounded-full h-3rem w-3rem z-1 cursor-pointer`}
                style={{  marginTop: '-25px' }}
                onClick={() => setActiveIndex(itemIndex)}
            >
                <div className='absolute text-xs bottom-[2.9rem] text-center font-semibold text-neutral-400'>{item.position}</div>
                <div>{item.icon}</div>
                <div className='absolute text-xs bottom-[-2.5rem] text-center'>{item.text}</div>
            </span>
        );
    };

    const items = [
        {
            fill:true,
            text:"Productos Seleccionados",
            position: 1,
            icon: <img src={IcFusil} width={25}/>,
            template: (item) => itemRenderer(item, 0)
        },
        {
            fill:true,
            text:"Productos Enviados",
            position: 2,
            icon: <img src={IcShip} width={25}/>,
            template: (item) => itemRenderer(item, 1)
        },
        {
            fill:true,
            text:"Revisión Aduanal",
            position: 3,
            icon: <img src={IcCustoms} width={25}/>,
            template: (item) => itemRenderer(item, 2)
        },
        {
            text:"En Almacén",
            position: 4,
            icon: <img src={IcStore} width={25}/>,
            template: (item) => itemRenderer(item, 2)
        }
    ];

    return (
        <div className="stepper-style1 mb-[2rem] mt-3">
            <Steps model={items} activeIndex={activeIndex} readOnly={false} className="m-2 pt-4" />
        </div>
    )
}
        