import { IoCartOutline } from "react-icons/io5";
import { LogoGelsoftMX } from "../../public/gelsoftmx/utilities/LogoGelsoftmx";
import { FaUserCircle } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import Ecommercecontext from "../../../EcommerceContext";
import { CartDialog } from "../../public/gelsoftmx/components/cart/CartDialog";

function PublicHeader() {
  const ecommerceContext = useContext(Ecommercecontext)
  const [visible, setVisible] = useState(false)
  return (
    <>
      <CartDialog setVisible={setVisible} visible={visible}></CartDialog>
      <div className="flex justify-between w-full bg-black px-3 items-center relative 
      z-20 sticky top-0
      " 
      style={{height:"4.5rem"}}>
            {/* LOGIN */}
            <div className="flex flex-col text-neutral-200 justify-center items-center text-center
            hover:scale-110 duration-500 cursor-pointer">
              <div className="flex"><FaUserCircle size={28}/></div>
              <div className="flex font1">Invitado</div>
            </div>

          {/* LOGO */}
          <div className="flex">
            <LogoGelsoftMX />
          </div>

          
          {/* KART */}
          <div className=" text-gray-100 flex flex-col items-center
            hover:scale-110 duration-500 cursor-pointer
          " onClick={() => { setVisible(true) }}>
            <div className="flex relative">
              <div> <IoCartOutline size={30}/> </div>
              <div className="flex items-center justify-center absolute top-[-.2rem] right-[-.5rem] w-[1rem] h-[1rem] rounded-[50%] bg-red-500 text-neutral-100 small bold text-align-center">
                {ecommerceContext.ecommerceContextData.cart.length}
              </div>
            </div>
            <div className="font1">Carrito</div>
          </div>
      </div>
    </>
  );
}

export default PublicHeader;
