import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import OtResume from "./OtResume";

export default function CreateOt({
  setShowOtModal,
  open,
  products,
  isUpdate,
  selectedOt
}) {
  const host = process.env.REACT_APP_API_URL;
  const cancelButtonRef = useRef(null);
  const [updatedProducts, setUpdatedProducts] = useState(products);
  const [total, setTotal] = useState(0)
  const [totalYN, setTotalYN] = useState(0)
  const [totalPZ, setTotalPZ] = useState(0)
  const [totalGan, setTotalGan] = useState(0)

  const [ot, setOt] = useState(selectedOt || { decripcion : ''})
  
  const totalOnChange = (productsTotal) => {
    let calculatedTotal = 0;
    let totalProducts = 0;
    let totalGan = 0;
    let aux = 0;
    productsTotal.forEach((product) => {
      totalProducts += product.cantidad
      calculatedTotal += (product.cantidad ) * product.precio;

      // aux para calcular la ganancia
      aux = (product.precio_sugerido) ? 
      (product.precio_sugerido * product.cantidad) :
      ( (product.precio * 4) * product.cantidad );

      totalGan += aux;
    });
    setTotal(calculatedTotal * 2.5);
    setTotalYN(calculatedTotal);
    setTotalPZ(totalProducts);
    setTotalGan(totalGan);
  }
  const createOt = async () => {
    const now = new Date().toISOString(); 
    const body = {
      id_estado: 1,
      id_moneda: 1,
      decripcion: ot.decripcion,
      obervaciones: '',
      totalYuan: totalYN,
      totalMxn: total,
      totalPcs: totalPZ,
      createdAt: now,
      updatedAt: now,
      deletedAt: null,
    }
    const response = await axios.post(`${host}/ot`, body )
    return response;
  }
  const createDetailOt = async(products) => {
    try {
      const response = await axios.post(`${host}/ot-detail`, products)
      return response;
    } catch (error) {
      console.error(error)
    }
  }
  const finishOt = async () => {
    const ot = await createOt();
    const body = {
      id_ot: ot.data.id,
      id_moneda: 1,
      details: [],
      createdAt: new Date().toISOString()
    }
    updatedProducts.map( (product) => {
      body.details.push({
        id_producto: product.id,
        cantidad: product.cantidad,
        precio: product.precio
      })
    })
    createDetailOt(body);
    setShowOtModal(false);
  }

  const handleDescription = async (e) => {
    setOt({
      ... {}, 
      ...ot,
      decripcion: e.target.value
    })
  }
  return (
    // Modal
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setShowOtModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel 
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all" 
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-900 "
                      >
                        { isUpdate ? "Actualizar OT" : "Crear Ot"}
                      </Dialog.Title>
                      <Dialog.Description>
                      { isUpdate ? "Actualizar" : "Crear"} una orden de compra
                        <div>
                          <input
                            type="text"
                            value={ selectedOt && selectedOt.decripcion}
                            onChange={ handleDescription }
                          >
                          </input>
                        </div>
                      </Dialog.Description>
                      <div>
                      <OtResume
                        updatedProducts={updatedProducts}
                        setUpdatedProducts={setUpdatedProducts}
                        totalOnChange={totalOnChange}
                        isUpdate={isUpdate}
                      >
                      </OtResume>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  { !isUpdate && (
                    <button
                      loading="true"
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                      onClick={finishOt}
                    >
                      Crear Ot
                    </button>
                  )}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setShowOtModal(false)}
                  >
                    { isUpdate ? "Cerrar" : "Cancelar"} 
                  </button>

                  <div>
                    <p>
                      Total MXN: $ 
                      <span >
                        { total }
                      </span>
                    </p>
                    <p>
                      Total YN: $ 
                      <span >
                        { totalYN }
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      Total PCS: 
                        <span>
                          { totalPZ }
                        </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      Total GAN: 
                        <span>
                          { totalGan }
                        </span>
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
