import React, { useContext, useState } from 'react';
import styles from '../../assets/css/products/card1.module.css';
import '../../assets/css/products/card1.css';

import AuthContext from '../../AuthContext';
import APP_CONSTANTS from '../../constants/app.constants';
import DropdownMenu from '../utilities/dropdownMenu';
import { EditInactiveIcon, DuplicateInactiveIcon, MoveInactiveIcon } from '../utilities/iconsProductsOptions';
import { StringToMXN } from '../../assets/utils/currency.utils';
import AlertModal from '../utilities/modals/alertModal';
import MyImageGallery from '../utilities/galery/galery';
import { AxiosWithHeader } from '../../assets/utils/curl.utils';
import { FaStar } from 'react-icons/fa';
import { SlOptionsVertical } from "react-icons/sl";

import { Dialog } from 'primereact/dialog';
import { TbEdit } from "react-icons/tb";
import { PiCopyDuotone } from "react-icons/pi";
import { BsDoorOpen } from "react-icons/bs";

import { Image } from 'primereact/image';
import { Checkbox } from 'primereact/checkbox';
import MenuProductCardTemplate from '../registered/products/templates/MenuProductCardTemplate';


export default function CardProducts({
  product, // producto a cargar 
  selectedRows, // array de los items seleccionados
  selectCard, // funcion para seleccionar la card
  editProduct, // funcion para editar un producto, ( espera un producto como parametro )
  setUpdatePage
}) {
  const authContext = useContext(AuthContext);
  const user = authContext.user.user
  const host = process.env.REACT_APP_API_URL;
  const [duplicateModal, setDuplicateModal] = useState(false)
  const [visibleMenu, setVisibleMenu] = useState(false)

  const [componentData, setcomponentData] = useState({
    refs: {

    }
  })

  const menuItems = [
    {
      label: "Editar",
      icon: <TbEdit size={20} />,
      onClick: () => {
        editProduct(product)
      },
    },
    {
      label: "Duplicar",
      icon: <PiCopyDuotone size={20} />,
      onClick: () => {
        setDuplicateModal(true)

      },
    },
    {
      label: "Abrir",
      icon: <BsDoorOpen size={20} />,
      onClick: () => {
        window.open(product.producto);
      },
    },
  ];



  let imgs = []
  if (product.imagenes && typeof product.imagenes == 'array') {
    imgs = product.imagenes
  }
  else if (product.imagenes) {
    imgs = [product.imagenes]
  }
  else if (product.photos) {
    imgs = product.photos
  }


  const copyProduct = async () => {
    const data = await AxiosWithHeader(`${host}/products-copy`, 'POST', product);
    setDuplicateModal(false)
    setUpdatePage(true)
  }

  const getImage = () => {
    let src = '';
    try {
      if (product?.imagenes) { src = (Array.isArray(product.imagenes) ? product.imagenes[0] : product.imagenes) }
      else if (product?.photos) { src = product.photos[0] }
    } catch (error) {

    }
    return src
  }
  const getDesc = () => {
    let desc = '';
    if (!product.descripcion && product.variaciones) { desc = product.variaciones.length + ' Variantes' }
    else if (product.descripcion) { desc = product.descripcion }

    return desc
  }
  return (
    <>

      <Dialog
        position='right'
        className='mr-2'
        header={null} draggable={false} visible={visibleMenu} onHide={() => setVisibleMenu(false)}>
        <MenuProductCardTemplate menuItems={menuItems} setVisible={setVisibleMenu} product={product} />
      </Dialog>

      <AlertModal
        open={duplicateModal}
        setOpen={setDuplicateModal}
        title={"Duplicar producto"}
        text={"Se duplicará el producto, seguro deseas proceder?"}
        action={copyProduct}
      />

      <div className={`${selectedRows.includes(product.id) ? styles["card-active"] : ''} 

        border-sm
        transition-transform 
        transform hover:translate-y-1 
        hover:border-neutral-400 hover:duration-600
        duration-300 ease-in-out
        bg-neutral-200 rounded-lg
    `}>

        {/* IMAGEN CARD */}
        <div className='max-h-[10rem] overflow-hidden rounded-lg'>
          {/* OPCIONES */}
          {APP_CONSTANTS.PERFIL.ADMIN == user.id_perfil && (
            <div className={`absolute z-10 flex justify-between w-full top-0`}>
              <div className='w-full ml-1 mt-1'>
                <Checkbox
                  className='checkbox-neutral'
                  onChange={e => selectCard(product.id)} checked={selectedRows.includes(product.id)}
                />
              </div>
              <div onClick={() => setVisibleMenu(true)}
                className="flex py-1 px-1 bg-neutral-200 rounded mt-1 mr-1 hover:scale-110 duration-300 ease-in">
                <SlOptionsVertical size={15} />
              </div>
            </div>
          )}
          <div className="">
            <MyImageGallery
              images={imgs}
            />
          </div>
          {/* <span className={styles["back-text"]}>GEL</span> */}
        </div>
        {/* BODY  ---->*/}
        <div >

          {/* DESCRIPCION DEL PRODUCTO */}
          <div className='flex flex-col font1 px-2 pb-1'>
            <div className="flex flex-col text-xs">
              <div className='text-m whitespace-nowrap overflow-hidden text-ellipsis font-semibold'>{product.nombre}</div>
              <div className='text-sm whitespace-nowrap overflow-hidden' style={{ marginTop: "-6px" }}>{getDesc()}</div>
            </div>

            <span className="text-xs" >
              <div className='flex flex-wrap justify-between'>
                {/* COSTO */}
                {user.id_perfil == APP_CONSTANTS.PERFIL.ADMIN && (
                  <div className='flex items-center'>
                    <div className='h-[.8rem] w-[3px] bg-red-500 mr-1'></div>
                    <div className='flex'>{StringToMXN(parseFloat(product.precio))}</div>
                  </div>
                )}
                {/* PRECIO */}
                <div className='flex items-center'>
                  <div className='h-[.8rem] w-[3px] bg-green-500 mr-1'></div>
                  <div className='flex'>
                    {product.precio_sugerido ? StringToMXN(product.precio_sugerido) : "$ 0.00"}
                  </div>
                </div>
              </div>

            </span>

          </div>
        </div>


      </div>
    </>
  );
}
