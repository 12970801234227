import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { PRICE_FORMAT } from '../../../assets/utils/general.utils';
import AuthContext from '../../../AuthContext';
import { OT_DETAIL_CONSTANTS } from '../../../constants/ot.constants';
import { FaArrowUp, FaArrowDown, FaCheck } from 'react-icons/fa'; // Cambiado de 'react-icons/fa6' a 'react-icons/fa'
import { RiShoppingCart2Line } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx'; // Cambiado de 'react-icons/rx' a 'react-icons/rx'
import { IoAlert, IoClose } from 'react-icons/io5';
import { BsBox } from 'react-icons/bs'
import { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { AxiosWithHeader } from '../../../assets/utils/curl.utils';



export const OtDetail = ({visible , setVisible, data , setData}) => {
    const authContext = useContext(AuthContext)
    const [itemsCalc , setItemsCalc] = useState({costo: 0 , gain: 0, products: 0, porc: 0})
    const [componentData, setComponentData] = useState({
        loading:false,
        confirmDialogData:{
            visible: false,
            text: ""
        }
    })

    const calcItems = () => {
        const dataItems ={
            costo: 0 , gain: 0, products: 0, porc: 0
        }
        if(data?.details){
            for (const detail of data?.details) {
                let {cantidad, precio , variaciones} = detail;

                if(variaciones){
                    cantidad = getProductQty(detail)
                    dataItems.costo += getProductCost(detail)
                    dataItems.gain += getProductGain(detail)
                }else{

                    dataItems.costo += parseInt(cantidad) * parseFloat(precio) 
                    dataItems.gain += parseInt(cantidad) * parseFloat(detail.precio_sugerido)
                }
                dataItems.products += cantidad

            }
            dataItems.porc = ( (dataItems.gain*100)/dataItems.costo ) / 100 
            dataItems.porc = parseFloat(dataItems.porc).toFixed(2)
            dataItems.costo = parseFloat(dataItems.costo).toLocaleString("es-MX",PRICE_FORMAT)
            dataItems.gain = parseFloat(dataItems.gain).toLocaleString("es-MX",PRICE_FORMAT)
        }
        setItemsCalc(dataItems)
    }
    const changeVariant = (variant, indexDetail, indexVar) => {
        const d = data
        d.details[indexDetail]['selected_var'] = indexVar
        setData({ ...d })
    }
    const handleInputChange = (e) => {
        const {name, value} = (e.target) ? e.target : e
        switch(name){
            
            // LOS QUE ESTAN EN VARIATION
            case 'cantidad':
                const d = data
                const detail = d.details[e.index];
                if(detail.variaciones){
                    d.details[e.index]['variaciones'][detail.selected_var][name] = value
                }else{
                    d.details[e.index][name] = value
                }
                
                setData(d)
                calcItems()
            break;
            case 'estado': case 'description': default:
                setData({ ...data, [name]: value  })
            break;
            
        }

    }
    const openConfirm = (value=null) => {
        setComponentData({...componentData,
            confirmDialogData: {
                visible: (value != null)? value : true
            }
        })
    }

    const saveOt = async () => {
        setComponentData({...componentData, loading: true})
        // VERIFICA QUE LOS DATOS ESTEN COMPLETOS
        if(!data.estado || !data.description){
            setComponentData({...componentData, loading: false})
            return
        }
        let [dataDetils,dataStructure] = [ null, data]

        if(!data.id){
            // CREA LA ESTRUCTURA ANTES DE ENVIAR:
            dataDetils = data.details.map( detail => {
                return {
                    id_producto: detail.id_producto,
                    nombre: detail.nombre,
                    precio: detail.precio,
                    photos: detail.photos,
                    precio_sugerido: detail.precio_sugerido,
                    sensitive_shipping: detail.sensitive_shipping,
                    variaciones: detail.variaciones
                }
            })
            dataStructure = {
                description: data.description,
                estado: data.estado,
                details: dataDetils
            }
        }

        try {
            const response = await AxiosWithHeader(`${authContext.host}/ot`,(data.id)?"PUT":"POST", dataStructure)
            authContext.toast.current.show({severity:"success", summary:"Ot creada exitosamente"})
        } catch (error) {
            authContext.toast.current.show({severity:"error", summary:"Error en la OT"})
        }
        setComponentData({...componentData, loading: false, confirmDialogData:{ ...componentData.confirmDialogData, visible: false}})
        setVisible(false)
    }
    
    const getActualQty = (detail) => {
        if(detail.variaciones){
            const selectedVar = detail.variaciones[detail.selected_var | 0]
            return (selectedVar?.cantidad) ? selectedVar?.cantidad : 0
        }
        return detail.cantidad
    }
    const getProductQty = (detail) => {
        if(detail.variaciones){
            return detail.variaciones.reduce( (total, value) => {
                return total + (value.cantidad | 0) 
            }, 0)
        }
        return detail.cantidad
    }
    const getProductCost = (detail) => {
        const { precio , cantidad , variaciones } = detail
        let priceProduct = 0
        
        if(variaciones){
            priceProduct = variaciones.reduce((total, p) => total + (parseFloat(p.cantidad | 0) * parseFloat(p.precio ? (p.precio) : precio ) )  , 0)
        }else{
            priceProduct = parseFloat(cantidad | 0) * parseFloat(precio | 0)
        }
        return priceProduct
    }
    const getProductGain =  (detail) => {
        const {cantidad ,variaciones, precio_sugerido} = detail

        let gainProduct  = 0
        if(variaciones){
            gainProduct = variaciones.reduce((total, p) => total + (parseFloat(p.cantidad | 0) * parseFloat(p.precio_sugerido ? (p.precio_sugerido) : precio_sugerido ) ) , 0 )
        }else{
            gainProduct = (parseFloat(cantidad | 0) * parseFloat(precio_sugerido | 0))
        }
        return gainProduct
    }
    const getImage = (detail) => {
        let src = ''; 
        if(detail?.imagenes){ src = (Array.isArray(detail.imagenes)? detail.imagenes[0]:detail.imagenes) }
        else if(detail?.photos){ src = detail.photos[0] }
        return src
    }
    useEffect(() => {

        calcItems()
    },[data])



    return (
        <>
            {/* CONFIRM */}
            <Dialog
                header={null} visible={componentData.confirmDialogData.visible} onHide={() => setComponentData({...componentData, confirmDialogData: {...componentData.confirmDialogData, visible:false}})} 
                className='dialog-header-hide'
                draggable={false} position='bottom'
                style={{ width: authContext.isMobile ? '65vw' : '20vw' }} >
                <div className="flex flex-col ">
                    <div className="flex flex-col items-center my-4">
                        <div className='flex'>
                            <IoAlert size={55} className='text-neutral-500' />
                        </div>
                        <div className='flex'>¿Seguro deseas proceder?</div>
                    </div>
                    <div className="flex justify-center gap-4 mb-2">
                        <Button disabled={componentData.loading} onClick={() => openConfirm(false)}
                        className="border-2 px-1 py-1 rounded text-rose-600 border-rose-600/60 cursor-pointer
                            hover:scale-110 hover:border-rose-600/90 duration-300 ease-in
                        ">
                            Cancelar
                        </Button>
                        <Button loading={componentData.loading} disabled={componentData.loading} onClick={()=> saveOt()}
                        className="border-2 px-1 py-1 rounded text-cyan-600 border-cyan-600/60 cursor-pointer
                            hover:scale-110 hover:border-cyan-600/90 duration-300 ease-in
                        ">
                            Aceptar
                        </Button>
                    </div>
                </div>
            </Dialog> 
            {/* SOLICITUD */}
            <Dialog 
                header={null} visible={visible} onHide={() => setVisible(false)} 
                className=""
                draggable={false} position='bottom'
                style={{ width: authContext.isMobile ? '95vw' : '35vw' }} 
            >
                <div className="flex flex-col mx-4 mt-2 mb-4 font1">

                    {/* TITULO */}
                    <div className='flex flex-col'>
                        <div className='relative text-2xl flex justify-center items-center'>
                            <div onClick={() => setVisible(false)}>
                                <RxCross2  className='absolute' style={{right:"-10px", top:" 5px"}} />
                            </div>
                            <div className='flex text-4xl text-indigo-500 bold'>D</div>
                            etalle de Solicitud</div>

                        {/* INPUTS */}
                        <div className='text-lg flex justify-between gap-4 my-3 '>
                            <div className="flex border-b-2 border-r-2 border-neutral-400  rounded-br-lg rounded-tr-lg rounded-bl ">
                                <InputText className='w-full'  value={data?.description} name='description' onChange={handleInputChange} /> 
                            </div>
                            <div className="flex border-b-2 border-r-2 border-neutral-400  rounded-br-lg rounded-tr-lg rounded-bl ">
                                <InputNumber  min={0} className='w-full' value={data?.totalFinal} name='totalFinal' onChange={(e) => handleInputChange({name: 'totalFinal' , value: e.value })} /> 
                            </div>
                            <div className="flex border-b-2 border-r-2 border-neutral-400  rounded-br-lg rounded-tr-lg rounded-bl w-1/4">
                                <Dropdown  
                                value={parseInt(data?.estado | 0)}
                                options={OT_DETAIL_CONSTANTS.OT_STATUS}
                                optionLabel="name" 
                                optionValue='id'
                                name='estado'
                                onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        {/* CALCS */}
                        <div className="flex justify-between items-center">
                            <div className="flex relative text-white">
                                <div className='rounded-lg border-2 border-cyan-600 bg-cyan-600 px-1 flex'><RxCross2 className='mt-1' /> {itemsCalc.porc}</div>
                                <div className='ml-1 rounded-lg border-2 border-cyan-500 bg-cyan-500 px-1 flex'>{itemsCalc.products} <RiShoppingCart2Line className='mt-1 ml-1' /></div>
                            </div>
                            <div className="flex">
                                <div><FaArrowDown size={20} color='#CB4335' /> </div>
                                <div className='ml-1'>$ {itemsCalc.costo}</div>
                            </div>
                            <div className="flex">
                                <div><FaArrowUp  size={20} color='#16A085'/> </div>
                                <div className='ml-1'>$ {itemsCalc.gain}</div>
                            </div>
                        </div>
                    </div>
                    {/* ITEMS */}
                    <div className='flex flex-col mt-2'>
                        {   data.details?.map((detail, i) => {
                            return (
                                <div className="flex border-b-2 py-1 gap-2">
                                    <div className="flex flex-col w-1/4 items-center justify-center relative">
                                        <Image src={getImage(detail)} loading={"lazy"} preview className=' h-20'/>
                                        <div className="flex border-2 px-1 py-0 bg-neutral-500 absolute rounded w-full text-white text-sm justify-center" style={{bottom:"0px"}}>
                                            {getProductQty(detail)} <BsBox  className='mt-1 ml-1'/> 
                                        </div>
                                    </div>
                                    <div className="flex flex-col ">
                                        <div className="flex">{detail.nombre}</div>
                                        <div className="flex mb-1">
                                            {detail.variaciones ?
                                            <div className='flex gap-1'>
                                                {detail.variaciones.map( (v,ii) => 
                                                    <div onClick={() => changeVariant(v, i,ii)} className={`border-2 rounded-md px-1 py-0 
                                                    ${((detail.selected_var | 0) == ii)? "border-emerald-600 bg-emerald-600 text-white ": " border-neutral-500 " }  `}>
                                                        {v.nombre}
                                                    </div>
                                                )}
                                            </div>:
                                            <>
                                                {detail.product?.descripcion}
                                            </>
                                            }
                                        </div>
                                            <div className="flex"> 
                                                <div className="flex">Qty:</div>
                                                <div className="flex ml-2">
                                                    <InputNumber name='cantidad' disabled={data.id} className='p-input-style1' value={getActualQty(detail)} 
                                                    onChange={(e) =>{ e.index = i; e.name = "cantidad"; handleInputChange(e) } } />
                                                </div>
                                            </div>
                                    </div>
                                        <div className="flex flex-col w-36">
                                            <div className='flex w-full justify-end'><span className='text-amber-500 mr-1'>P:</span> $ {parseFloat(detail.precio  | 0).toLocaleString("es-MX",PRICE_FORMAT)}</div>
                                            <div className='flex w-full justify-end'><span className='text-rose-600 mr-1'>T:</span> $ {getProductCost(detail).toLocaleString("es-MX",PRICE_FORMAT)}</div>
                                            <div className='flex w-full justify-end'><span className='text-emerald-600 mr-1'>G:</span> $ { getProductGain(detail).toLocaleString("es-MX",PRICE_FORMAT)}</div>
                                            
                                        </div>
                                </div>
                            )
                        })

                        }
                       
                    </div>
                    {/* BUTTONS */}
                    <div className='flex justify-between mt-2 text-neutral-500'>
                        <div onClick={() => setVisible(false)} className="border-2 rounded-sm p-1 rounded border-rose-300 flex">CANCELAR <IoClose size={22} className='ml-1' /></div>
                        <div onClick={openConfirm} className="border-2 rounded-sm p-1 rounded border-indigo-300 flex">GUARDAR<FaCheck size={18} className='ml-2 mt-1' /></div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}