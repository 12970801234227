import { Menu, Transition } from '@headlessui/react'
import { Fragment} from 'react'
import DropdownMenuItem from './dropdownMenuItem';
import { MenuIcon } from './iconsProductsOptions';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';


// const menuItems = [
//   {
//     text: "Editar",
//     icon: EditInactiveIcon,
//     onClick: () => {
//       editProduct(product)
//     },
//   }
// ];

export default function DropdownMenu({ items , text = null , className = ''}) {
    return (
        <Menu as="div" className={"inline-block text-left w-full " + className}>

          { text ? (
            <div>
              <Menu.Button className="
                w-full
                group inline-flex items-center rounded-md bg-black px-2 py-1 
                font-medium text-white hover:text-opacity-100 focus:outline-none 
                focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
              ">
                { text }
                <ChevronDownIcon
              className="ml-2 -mr-1 h-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
              </Menu.Button>
            </div> 
          ):(  
          <div>
            <Menu.Button className="
            option-button-rounded
            flex max-w-xs items-center rounded-full 
            bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white 
            focus:ring-offset-2 focus:ring-offset-gray-800"

            >
              <span className="sr-only">Open user menu</span>
              <MenuIcon
                className=" 
                  icon-option-button-rounded
                  h-4 w-4 text-violet-200 
                  hover:text-violet-100"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {items.map((item, index) => (
                <DropdownMenuItem key={index} {...item} />
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
    );
  }