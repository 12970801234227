import React from 'react';
import { IoSearch } from "react-icons/io5";


export default function SearchTable({searchTerm, handleSearchTerm , isLoading}) {

    {/* BUSCADOR */}
    return (
        <div className="flex items-center px-2 border-2 border-slate-800 rounded-md w-full">
            
            <IoSearch size={15} />


            <input
            className="border-none outline-none focus:border-none focus:ring-transparent text-xs w-full"
            type="text"
            placeholder="Buscar producto..."
            value={searchTerm}
            onChange={handleSearchTerm}
            />

            { isLoading ? (
                <div className="w-5 h-5">
                    <div className="w-5 h-6 border-t-4 border-purple-500 border-solid rounded-full animate-spin"/>
                </div>
            ):
            <div className='flex gap-1'>
                <div></div>
            </ div>
            }
        </div>
    );
}
