
// Función auxiliar para agregar campos al FormData
const addFieldsToFormData = (formData, prefix, obj) => {

  for (const key in obj) {
    if(!prefix && key == 'variaciones'){
      continue;
    }
      formData.append((prefix)?`${prefix}[${key}]`:`${key}`, obj[key]);
  }
};
// Función auxiliar para agregar fotos al FormData
const addPhotosToFormData = (formData, prefix, photos) => {
  if (photos) {
      photos.forEach(( photo, index) => {
          formData.append(`${prefix}[${index}]`, photo);
      });
  }
};
// Función auxiliar para agregar variaciones al FormData
const addVariationsToFormData = (formData, variations) => {
  if (variations) {
      variations.forEach((variation, i) => {
          const variationPrefix = `variaciones[${i}]`;
          addFieldsToFormData(formData,variationPrefix, variation);
          addPhotosToFormData(formData,`${variationPrefix}[photos]`, variation.photos);
      });
  }
};
export const getProductFormData = (element) => {
  const formData = new FormData();
  delete element.general_photos
  delete element.imagenes
  // Agregar campos principales al FormData
  addFieldsToFormData(formData,'', element);
  // Agregar fotos principales al FormData
  addPhotosToFormData(formData,'photos', element.photos);
  // Agregar variaciones al FormData
  addVariationsToFormData(formData,element.variaciones);
  return formData;
};
