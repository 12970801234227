import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { IoStorefrontOutline } from "react-icons/io5";
import { CiBoxes } from "react-icons/ci";
import { PiTicketBold } from "react-icons/pi";
import AuthContext from "../../../AuthContext";


const imgUrl = 'https://freesvg.org/img/abstract-user-flat-1.png';

const menuItems = [
  {
    path: "/admin/inventory",
    icon: <CiBoxes size={25}/>,
    isIcon: true,
    text: "Productos",
  },
  {
    path: "/admin/manage-store",
    icon: <PiTicketBold size={21}/>,
    isIcon: true,
    text: "Solicitudes",
  },
  {
    path: "/admin/shipments",
    icon: <IoStorefrontOutline size={22} />,
    isIcon: true,
    text: "Envíos",
    // condition: APP_CONSTANTS.PERFIL.ADMIN,
  },
  {
    path: "/admin/usuarios",
    icon: <IoStorefrontOutline size={22} />,
    isIcon: true,
    text: "Usuarios",
    // condition: APP_CONSTANTS.PERFIL.ADMIN,
  },
  {
    path: "/admin/stock",
    icon: <IoStorefrontOutline size={22} />,
    isIcon: true,
    text: "Almacén",
    // condition: APP_CONSTANTS.PERFIL.ADMIN,
  },
];

function SideMenu() {
  const authContext = useContext(AuthContext)
  const localStorageData = authContext.user ? authContext.user.user : {};
  

  return (
    <div className="h-full flex-col justify-between bg-white hidden lg:flex w-full">
      <div className="pb-6">
        <nav aria-label="Main Nav" className="mt-6 flex flex-col space-y-1">
          {menuItems.map((item, index) => (
            <details
              key={index}
              className="group [&_summary::-webkit-details-marker]:hidden h-7"
            >
              <summary className="flex cursor-pointer  items-center justify-between rounded-lg px-2 py-1 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                {item.condition === undefined || item.condition === localStorageData.id_perfil ? (
                  <Link to={item.path} className="no-underline text-zinc-600">
                    <div className="flex items-center gap-2">
                      {item.isIcon ? item.icon : <img src={item.icon} />}
                      <span className="text-sm font-medium font1">{item.text}</span>
                    </div>
                  </Link>
                ) : null}
              </summary>
            </details>
          ))}
        </nav>
      </div>

      <div className="sticky inset-x-0 bottom-0 border-t border-gray-300">
        <div className="flex items-center gap-2 bg-white p-1 hover:bg-gray-50">
          <img
            alt="Profile"
            src={imgUrl}
            width={25}
            className="rounded-full object-cover"
          />

          <div>
            <div className="text-xs font1">
              <strong className="block font-medium">
                {localStorageData.username}
              </strong>
              <span> {localStorageData.email} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
