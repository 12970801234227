import { Button } from "primereact/button";
import {  ShipmentsList } from "../../components/registered/shipments/Shipments"
import { LuShip } from "react-icons/lu";
import { FiPlus } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../AuthContext";
import { AxiosWithHeader } from "../../assets/utils/curl.utils";
import { Dialog } from 'primereact/dialog';
import { SET_VALUE_TO_STATE } from "../../assets/utils/general.utils";
import { FaRegFaceSadCry } from "react-icons/fa6";
import { ShipmentForm } from "../../components/registered/shipments/ShipmentForm";

export const ShipmentsPage = ({}) => {
    const authContext = useContext(AuthContext)
    const [componentData , setComponentData ] = useState({
        loading: true,
        visibleForm: false,
        activeOts: [],
        shipments: { list:[] , search: [] },
        dataForm: {  }
    })

    useEffect( () => {
        Promise.all([
            AxiosWithHeader(`${authContext.host}/private/shipment`)
        ]).then(([shipmentsData]) => {
            setComponentData({... componentData, 
                activeOts: shipmentsData.data.otActives,
                shipments: { ...componentData.shipments, search: shipmentsData.data.shipments, list:shipmentsData.data.shipments }
            })
        }).catch(err => {

        })
    }, [])

    const saveShipment = (shipment) => {
        AxiosWithHeader(`${authContext.host}/private/shipment` , (shipment.id) ? "PUT":"POST" , shipment).then( () => {
            reloadShipment()
        })  
    }
    const deleteShipment = (shipment) => {
        AxiosWithHeader(`${authContext.host}/private/shipment` , "DELETE" , { id: shipment.id }).then( () => {
            reloadShipment()
        })
    }
    const reloadShipment = () => {
        AxiosWithHeader(`${authContext.host}/private/shipment`, "GET").then( (shipmentsData) => {
            setComponentData({... componentData, dataForm:{}, visibleForm: false, shipments: { ...componentData.shipments, search: shipmentsData.data, list:shipmentsData.data }})
        })
    }
    const openForm = (shipment) => {
        setComponentData({... componentData,
            dataForm: shipment,
            visibleForm: true
        })
    }


    return (
        <div className={`col-span-${authContext.colSpan} flex flex-col justify-center p-4`}>
            <ShipmentForm 
            shipmentData={componentData} 
            setShipmentData={setComponentData} 
            aceptFn={saveShipment} deleteFn={deleteShipment} />
            <div className="w-full font1 flex flex-col gap-2 border-2 border-gray border-solid rounded p-2 bg-white">
                <div className="header-shipmets-card flex flex-col mb-2">
                    <div className="flex gap-2">
                        <div className="text-2xl font1">
                        Listado de Envíos
                        </div>
                        <LuShip size={25}/>
                    </div>
                    <div className="flex gap-2 mt-2">
                        <Button onClick={() => {SET_VALUE_TO_STATE(componentData,"visibleForm",true, setComponentData)}} className="py-1 px-2 border-solid border-2 border-black rounded">Nuevo Envío <FiPlus className="ml-1" size={20}  /></Button>
                        {/* <Button className="py-1 px-2 border-solid border-2 border-black rounded">Buscar <FiPlus className="ml-1" size={20}  /></Button> */}
                    </div>
                </div>

                <div className="body-shipments-card dlex flex-col gap-2 ">
                    { componentData.shipments.search.length > 0 ?
                        <ShipmentsList ShipmentsList={componentData.shipments.search} openModal={openForm} />:
                        <div className="flex flex-col justify-center items-center" style={{height:"30vh"}}>
                            <div><FaRegFaceSadCry size={55} color="gray"/></div>
                            <div className="text-xl">Sin envíos</div>
                            <div className="text-md">Registra un nuevo envio!</div>
                        </div>
                    }
                </div>
            </div>
        </ div>
    )
}