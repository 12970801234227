import { SHIPPING_CONSTANTS } from "../../../../constants/shipping.constants";

// Define el array de objetos para los campos del formulario
export const ReturnShipmentFormFields = (shipmentData , isMobile) => {
    return [
    { cols:(isMobile) ? "2" : "3", label: 'Nombre del envío', type: 'text', name: 'shipmentName' , value: shipmentData.dataForm.shipmentName },
    { cols:(isMobile) ? "2" : "3", label: 'Agente', type: 'dropdown',options: SHIPPING_CONSTANTS.AGENTS, name: 'agent' , value: shipmentData.dataForm.agent },
    { cols:(isMobile) ? "2" : "3", label: 'Precio', type: 'number', min: 0, name: 'price' , value: shipmentData.dataForm.price },
    { cols:(isMobile) ? "2" : "3", label: 'Status', type: 'dropdown',options: SHIPPING_CONSTANTS.STATUS, name: 'status' , value: shipmentData.dataForm.status },
    { cols:(isMobile) ? "2" : "3", label: 'Fecha Inicio', type: 'date', name: 'startDate' , value: shipmentData.dataForm.startDate },
    { cols:(isMobile) ? "2" : "3", label: 'Fecha Fin', type: 'date', name: 'endDate' , value: shipmentData.dataForm.endDate },
    { cols:"1", label: 'Ordenes de trabajo', type: 'multidropdown', options: shipmentData.activeOts, name: 'workOrders' , value: shipmentData.dataForm.workOrders },
    { cols:"1", label: 'Descripción', type: 'textarea', name: 'description' , value: shipmentData.dataForm.description },
  ];
}