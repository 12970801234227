import { object } from 'prop-types';
import React, { useRef, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import Gallery, {LeftNav} from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const customStyles = {
  // Agrega tus estilos personalizados aquí
  // Por ejemplo:
  leftNav: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Fondo del botón
    color: '#333', // Color del icono
  },
};

const MyImageGallery = ({images}) => {
  const refGallery = useRef() 
  const getRef = () => refGallery
  const imgs = Array.isArray(images) ? images : [images]
  const imagesOneArray =  imgs.reduce((total, image) => {
    if(typeof image == 'string'){
      total.push({ original: image })
    }else if(Array.isArray(image)){
      for (const image2 of image) {
        total.push({original: image2})
      }
    }
    return total
  },[])

  return <Gallery 
    ref={refGallery}
    items={imagesOneArray} 
    showPlayButton={false}
    showFullscreenButton={false}
    onClick={() => { refGallery.current.toggleFullScreen() }}
    renderLeftNav={(onClick, disabled) => {
      return <FaAngleLeft className='absolute top-[2.5rem] z-20 text-neutral-600' size={40} onClick={onClick} disabled={disabled} />
    }}
    renderRightNav={(onClick, disabled) => {
      return <FaAngleRight className='absolute top-[2.5rem] z-20 right-0 text-neutral-600' size={40} onClick={onClick} disabled={disabled} />
    }}
  />;
};

export default MyImageGallery;
