import React, { useState, useEffect  } from "react";
import APP_CONSTANTS from "../constants/app.constants";
import AuthContext from "../AuthContext";

import { useContext , useRef } from "react";


import PRODUCTS_CONSTANTS from "../constants/products.constants";

import ShowOtModal from "../components/ot/CreateOt";
import DropdownMenu from "../components/utilities/dropdownMenu";
import PopoverFilter from "../components/utilities/popover/popover";
import CardProducts from "../components/productos/CardProducts";
import { EditInactiveIcon } from "../components/utilities/iconsProductsOptions";
import { ProductsForm } from "../components/registered/products/ProductsForm";

import { AxiosWithHeader, debouncePromises } from "../assets/utils/curl.utils";
import { orderBy } from "lodash";

import { TiThumbsOk } from "react-icons/ti";
import { BiSolidCommentAdd } from "react-icons/bi";
import IcProducts from "../assets/icons/product-icon.svg"

import '../assets/css/inventory/index.css'
import { FN_PAGINATE_ARRAY } from "../assets/utils/general.utils";
import PRPaginator from "../components/utilities/lists/paginator";
import { ProductsCardsOptions } from "../components/registered/products/ProductsCardsOptions";
import { getProductFormData } from "../components/registered/products/js/ProductsUtils";
import { OtDetail } from "../components/registered/ot/OtDetail";
import { IoSearch } from "react-icons/io5";

function Inventory() {

  const authContext       = useContext(AuthContext);
  const user              = authContext.user.user
  const prevUpdatePageRef = useRef(null);

  const [showOtModal, setShowOtModal] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState({ 
    id_category: null,
    id_subcategory: null
   });
  
  const [updatePage, setUpdatePage] = useState(false);
  
  const [searchTerm, setSearchTerm] = useState();
  const [ isLoading, setIsLoading ] = useState(true);
  let timer
  
  const [componentData , setComponentData ] = useState({
    loading: true,
    visibleForm: false,
    products: { list:[] , search: [], paginated: [] },
    dataForm: {  },
    catalogues: {
      activeOts: []
    },
    pageSize: 10, currentPage:0, 
  })
  
 
  
  useEffect(() => {
    if (updatePage !== prevUpdatePageRef.current)
      fetchProductsData();

    // Actualiza la referencia del valor anterior
    prevUpdatePageRef.current = updatePage;
  }, [updatePage])

  useEffect(() => {
    fetchSearchData();
  }, [searchTerm ,filters, componentData.pageSize, componentData.currentPage]);

  
  // Fetching Data of All Products
  const fetchProductsData = async () => {
    timer = debouncePromises([
      AxiosWithHeader(`${authContext.host}/products`,'GET'),
      AxiosWithHeader(`${authContext.host}/ot/active`,'GET')
      ], ([productsData, otActiveData]) => {
        const productsDataVal = orderBy(productsData.data, ['created_at'],['desc']);
        setComponentData({...componentData, catalogues:{ ...componentData.catalogues, activeOts: otActiveData.data }})
        fetchSearchData(productsDataVal);
        setIsLoading(false)
    })
  };
  // Fetching Data of Search Products
  const fetchSearchData = (allProducts = componentData.products.list ) => {
    let data = allProducts ; 
    if(searchTerm){
        data = data.filter( row =>  JSON.stringify(row).toUpperCase().includes(searchTerm.toUpperCase()) )
    }
    if(filters.id_category){
      data = data.filter( row => {
        let catego = (row.id_categoria) ? row.id_categoria + '' : 0;
        catego.toString()
        
        try {
          return (filters.id_category == catego || (catego &&  catego.includes(`${filters.id_category}-`)) ) 
        } catch (error) {
          console.log([catego, row])
        }
      })
    }
    if(filters.id_subcategory){
      data = data.filter( row =>  filters.id_subcategory == row.id_subcategoria )
    }
    data = orderBy(data, ['created_at'],['desc']);
    // PAGINATE 
    const paginated= FN_PAGINATE_ARRAY(data, componentData.pageSize, componentData.currentPage)

    setComponentData({... componentData, 
      visibleForm: false,
      dataForm: {},
      products : { list: allProducts , search: data , paginated}
    })
  };

  // Modal for Product ADD
  const addProductModalSetting = () => setComponentData({... componentData,  dataForm:{}, visibleForm: true})
  // Modal for Product UPDATE
  const updateProductModalSetting = (selectedProductData) => setComponentData({...componentData,visibleForm: true,dataForm: selectedProductData })


  // Delete item
  const deleteItem = (element) => {
    AxiosWithHeader(`${authContext.host}/products/${element.id}`,"DELETE")
      .then((data) => {
        setUpdatePage(!updatePage);
      });
  };
  const createOrUpdateProduct = (element) => {
    const formData = getProductFormData(element)
    AxiosWithHeader(`${authContext.host}/products`,  element.id ? "PUT" : "POST" , formData , "multipart/form-data").then( () => {
      fetchProductsData()
    } )
  }
  const createOt = () => {
    const productsToCreateOrder = componentData.products.list.filter( (p) => { return selectedRows.includes(p.id) })
    const details = productsToCreateOrder.map( (p) => {
      return {
        nombre: p.nombre,
        precio: p.precio,
        precio_sugerido: p.precio_sugerido,
        sensitive_shipping: p.sensitive_shipping,
        selected_var: 0,
        id_producto: p.id,
        photos:p.photos ,
        variaciones: p.variaciones.map( v => {
          v.cantidad = 0
          return v
        })
      }
    })
    const objectToCreate = {
      estado: 1,
      totalFinal: 0,
      details
    }
    setSelectedProducts(objectToCreate);
    setShowOtModal(true);
  };
  // Handle Search Term
  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const setCurrentPage = (page) => {
    setComponentData({...componentData, currentPage: page})
  }
  const toggleCard = (id) => {
    if(APP_CONSTANTS.PERFIL.ADMIN != user.id_perfil ){ return; }
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const menuItems = [
    {
      text: "Crear OT",
      icon: EditInactiveIcon,
      onClick: () => {
        createOt()
      },
    },
    {
      text: "Crear Producto",
      icon: BiSolidCommentAdd,
      onClick: () => {
        addProductModalSetting()
      },
    },
    {
      text: "Limpiar",
      icon: TiThumbsOk,
      onClick: () => {
        setSelectedRows([])
      },
    },
  ];

  
  return (
    <div className={`col-span-${authContext.colSpan} flex flex-col justify-center`}>

      <ProductsCardsOptions filters={filters} setFilters={setFilters} setCurrentPage={setCurrentPage} />

      <div className={`flex flex-col mx-${authContext.isMobile ? 2 : 4}`}>
        {showOtModal && (
          <OtDetail
            visible={showOtModal}
            setVisible={setShowOtModal}
            data={selectedProducts}
            setData={setSelectedProducts}
          />
        )}
        {componentData.visibleForm && (
          <ProductsForm
            obj={componentData}
            setObj={setComponentData}
            aceptFn={createOrUpdateProduct}
            deleteFn={deleteItem}
          />
        )}

        
        {/* PRODUCTOS */}
        <div className="overflow-visible rounded-lg border items-center bg-white border-gray-200 position: relative p-2">

        
            <div className="flex flex-wrap justify-center items-center w-full gap-2">
              {/* TITULO */}
              <div className="flex items-center justify-between align-center w-full">
                <span className="font1 flex flex-col">
                  <div className="text-2xl flex mt-2 relative"> 
                    <div className="relative">
                      <div className="flex items-end">
                        <div className="flex text-cyan-600 text-4xl">N</div>
                        <div className="flex">uestros Productos</div>
                      </div>
                      <span className="ml-1 absolute" style={{right:"-2.5rem", top:"0rem"}}><img src={IcProducts} width={40} /></span>
                    </div> 
                  </div>
                  <div className="text-md "> Visualiza nuestros productos disponibles para importación</div>
                </span>
                <div>
                 <PRPaginator 
                 totalRecords={componentData.products.search ? componentData.products.search?.length : 0}
                  pageSize={componentData.pageSize}
                  currentPage={componentData.currentPage} 
                  setData={(data) => {
                    setComponentData({...componentData, pageSize:data.rows, currentPage: data.page})
                  }}
                />
                </div>
              </div>
              <div className="flex gap-2 w-full">
                {/* BUSCADOR */}
                <div className="flex  w-full  justify-center items-center px-2 border-2 rounded-md border-slate-900 ">
                  <IoSearch size={15} />
                  <input
                    className="border-none outline-none focus:border-none focus:ring-transparent text-xs w-full"
                    type="text"
                    placeholder="Buscar producto..."
                    value={searchTerm}
                    onChange={handleSearchTerm}
                  />
                  { isLoading && (
                    <div className="w-5 h-5">
                      <div className="w-5 h-6 border-t-4 border-purple-500 border-solid rounded-full animate-spin"/>
                    </div>
                  )}
                </div>
                {/* OCPIONES */}
                <div className="flex gap-1">
                  <div className="float-right hidden">
                    <PopoverFilter
                      catalogue={PRODUCTS_CONSTANTS.CATEGORIA}
                      subcatalogue={PRODUCTS_CONSTANTS.SUBCATEGORIA}
                      setfilters={setFilters}
                    ></PopoverFilter>
                  </div>
                  { APP_CONSTANTS.PERFIL.ADMIN == user.id_perfil && (
                    <div className="options-inventory-dropdown">
                      <DropdownMenu
                        items={menuItems}
                        text={"Más"}
                      />
                    </div>
                  )}
                </div>
              </div>

            </div>

          {/* </div> */}
          <div className="card-container pt-1 mt-3 flex flex-wrap gap-2 overflow-y-scroll " style={{maxHeight:"65vh"}}>
            {componentData.products.paginated.map((product) => (
              <div className="card-item" key={product.id}>
                <CardProducts 
                  product={product} 
                  selectCard={toggleCard}
                  selectedRows={selectedRows}
                  editProduct={updateProductModalSetting}
                  setUpdatePage={setUpdatePage}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
