import React, {useState} from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';

    const qtyStyle = {
        maxWidth: "90px"
    }
    
    export default function OtResume({ 
        updatedProducts,
        setUpdatedProducts,
        totalOnChange,
        isUpdate
    }){
        useEffect(() => {
            if(isUpdate){
                totalOnChange(updatedProducts);
            }
        },[isUpdate])
        // Esta función se llama cuando cambia la cantidad en un input
        const handleQuantityChange = (row, newQuantity) => {
            const updatedProduct = { ...row, cantidad: parseInt(newQuantity, 10) };
            const updatedProductsArray = updatedProducts.map((product) =>
            product.id === updatedProduct.id ? updatedProduct : product
            );
            setUpdatedProducts(updatedProductsArray);
            totalOnChange(updatedProductsArray);
        };

        const columns = [
            {
                name: 'Img',
                selector: row => row.nombre,
                cell: (row) => (
                    <img src={row.imagenes || row.imagenes?.[0] || row.url?.[0] } width="100px"/>
                ),
                // sortable: true,
            },
            {
                name: 'Nombre',
                selector: row => row.nombre,
                // sortable: true,
            },
            {
                name: 'Variante',
                selector: row => row.descripcion,
                // sortable: true,
            },
            {
                name: 'Cantidad',
                    selector: row => row.cantidad,
                    cell: (row) => (
                        <input
                            type="number"
                            min="0"
                            disabled={isUpdate ? true: false}
                            style={qtyStyle}
                            value={row.cantidad}
                            onChange={(e) => handleQuantityChange(row, e.target.value)}
                        />
                    ),
            },
            {
                name: 'Price YN',
                selector: row => row.precio
            },
            {
                name: 'Price MXN',
                selector: row =>'$' + ( row.precio * 2.5 )
            },
            {
                name: 'Total',
                selector: row => '$' +  (row.precio * 2.5 * row.cantidad)
            }
        ];

        return (
            <DataTable
                columns={columns}
                data={updatedProducts}
            />
        );
    };