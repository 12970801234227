import PRODUCTS_CONSTANTS from "../../../constants/products.constants";

export const ProductsCardsOptions = ({filters, setFilters, setCurrentPage}) => {
    const clickCard = (category) => {
        let id_category = (category.id == '0') ? null : category.id
        setCurrentPage(0)
        setFilters({...filters, id_category})
    }
    return (
        <>
      <div className="options-inventory flex px-3 gap-2 overflow-x-scroll min-h-24 items-center">
            {/* CARDS OPTIONS */}
            {PRODUCTS_CONSTANTS.CATEGORIA.map(cat => {
                let classActive = 'border-neutral-500';
                if(filters.id_category === null && cat.id == '0'){ classActive = 'border-black' }
                if(filters.id_category == cat.id){ classActive = 'border-black' }
                return <div className={`
                border-solid border-2 rounded max-h-16 min-w-24 
                px-2 pt-1 py-0 flex flex-col items-center font1 cursor-pointer
                hover:scale-105 hover:border-black  duration-300
                ${classActive }
                `}
                onClick={() =>clickCard(cat)}
                >
                    <div>{cat.icon}</div>
                    <div>{cat.name}</div>
                </div>
            })
            }

          
        </div>
        </>
    )
}