import React, { useState, useEffect } from "react";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    // Consulta los breakpoints de manera dinámica
    const mediaQueryList = window.matchMedia(query);

    // Actualiza el valor de matches
    setMatches(mediaQueryList.matches);
  }, []);

  return matches;
};

 export function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return { width, isMobile };
}


export default {useMediaQuery, useWindowSize};