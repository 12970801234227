import { useState } from 'react'
import { TfiWorld } from 'react-icons/tfi'

import IcAll from "../../../../../assets/imgs/gelsoftmx/home/cards/pistola (1).png"
import IcCort from "../../../../../assets/imgs/gelsoftmx/home/cards/pistola.png"
import IcLarg from "../../../../../assets/imgs/gelsoftmx/home/cards/fusil-de-asalto.png"
import IcAcc from "../../../../../assets/imgs/gelsoftmx/home/cards/balas.png"

export const FiltersHome = ({ fatherData , setFatherData, changePage }) => {
    const [items, setItems] = useState([
        { key:"0", icon: <img src={IcAll} width={45}/>, name: "Todo", selected: true },
        { key:"1", icon: <img src={IcCort} width={45}/>, name: "Cortas" },
        { key:"2", icon: <img src={IcLarg} width={45}/>, name: "Sub-Fusil" },
        { key:"3", icon: <img src={IcLarg} width={45}/>, name: "Fusil" },
        { key:"4", icon: <img src={IcAcc} width={45}/>, name: "Accesorios" },
    ])
    const selectCard = (index) => {
        setFatherData({...fatherData, products: {... fatherData.products, loading: true} })
        const newItems = items.map((it, i) => {
            it.selected = (i == index) ? true : false
            return it
        })
        const itemsFiltered = handleFilter(newItems.find(ni => ni.selected))
        setItems(newItems)
        setFatherData({...fatherData, products: {... fatherData.products, loading: false } })
        setTimeout(() => {
            changePage(null, itemsFiltered)
        }, 100);
    }
    const handleFilter = (itemSelected) => {
        const filterKey = itemSelected.key;
        const productList = fatherData.products.list;
        switch (filterKey) {
            case '1': case '2': case '3': case '4':
                return productList.filter(p => p.id_categoria.includes(`6-${filterKey}`));
            case '0': default:
                return productList;
        }
    }
    return (
        <div className="flex gap-2 py-2 px-2 font8 
        max-[600px]:overflow-x-scroll min-[600px]:justify-center">
            {items.map((item, index) => {
                return (
                    <div
                        key={`fh-${index}`}
                        onClick={() => selectCard(index)}
                        className={`border-2 rounded px-2 py-1 min-w-20 items-center
                                hover:scale-105 duration-300 cursor-pointer
                                ${(item.selected) ? "border-black scale-105 " : "border-neutral-600/50"}
                                `}>
                        <div className="flex justify-center">{item.icon}</div>
                        <div className="flex justify-center text-base">{item.name}</div>
                    </div>
                )
            })
            }
        </div>
    )
}