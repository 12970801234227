import { LiaGamepadSolid } from 'react-icons/lia';
import { PiShirtFoldedDuotone } from "react-icons/pi";
import { AiFillGold } from "react-icons/ai";
import { FaMobileAlt , FaFootballBall, FaUserNinja , FaFemale, FaHandsHelping} from "react-icons/fa";
import { FaGun } from "react-icons/fa6";

import IcToy from "../assets/icons/toy.svg"
import IcGun from "../assets/icons/gun-ak.svg"
import IcHeel from "../assets/icons/heel.svg"
import IcGlasses from "../assets/icons/glasses.svg"
import IcSmart from "../assets/icons/smart-watch.svg"
import IcWorld from "../assets/icons/world.svg"



export const PRODUCTS_CTEGORIA =[ 
  {
  key: '1',
  label: 'Ropa',
  data: 'Ropa para hombes y mujer',
  icon: <img src={IcWorld} width={35} />,
  children: [
    {
      key: '1-1',
      label: 'Dama',
      data: 'Ropa mujer',
      icon: <img src={IcWorld} width={35} />,
    },
    {
      key: '1-2',
      label: 'Hombre',
      data: 'Ropa mujer',
      icon: <img src={IcWorld} width={35} />,
    },
    {
      key: '1-3',
      label: 'Niños',
      data: 'Ropa mujer',
      icon: <img src={IcWorld} width={35} />,
    }
  ]
},{
  key: '2',
  label: 'Juguetes',
  data: 'Ropa para hombes y mujer',
  icon: <img src={IcWorld} width={35} />,
  children: [
    {key: '2-1', label: "NIÑOS",data: '', icon: LiaGamepadSolid},
    {key: '2-2', label: "HOMBRE",data: '', icon: FaUserNinja},
    {key: '2-3', label: "MUJER",data: '', icon: FaFemale},
    {key: '2-4', label: "UNISEX",data: '', icon: FaHandsHelping}
  ]
},{
  key: '3',
  label: 'Accesorios',
  data: 'Ropa para hombes y mujer',
  icon: <img src={IcWorld} width={35} />,
  children: [
    {key:'3-1', name: "DEPORTIVA", description: '', icon: FaFootballBall},
    {key:'3-1', label: "NIÑOS",data: '', icon: LiaGamepadSolid},
    {key:'3-2', label: "HOMBRE",data: '', icon: FaUserNinja},
    {key:'3-3', label: "MUJER",data: '', icon: FaFemale},
    {key:'3-4', label: "UNISEX",data: '', icon: FaHandsHelping}
  ]
},{
  key: '4',
  label: 'Electrónica',
  data: 'Ropa para hombes y mujer',
  icon: <img src={IcWorld} width={35} />,
  children: [
    {key:'4-1', name: "TELEFONÍA", data: '', icon: FaFootballBall},
  ]
},{
  key: '5',
  label: 'Calzado',
  data: 'Ropa para hombes y mujer',
  icon: <img src={IcWorld} width={35} />,
  children: [
  {key: '5-1', label: "DEPORTIVA", data: '', icon: FaFootballBall},
  {key: '5-2', label: "NIÑOS",data: '', icon: LiaGamepadSolid},
  {key: '5-3', label: "HOMBRE",data: '', icon: FaUserNinja},
  {key: '5-4', label: "MUJER",data: '', icon: FaFemale},
  {key: '5-5', label: "UNISEX",data: '', icon: FaHandsHelping},
  {key: '5-6', label: "HIDROGEL",data: '', icon: FaGun},
  ]
},{
  key: '6',
  label: 'Hidrogel',
  data: 'Ropa para hombes y mujer',
  icon: <img src={IcGun} width={35} />,
  children: [
  {key: '6-1', label: "Cortas",data: '', icon: FaGun},
  {key: '6-2', label: "Sub-Fusil",data: '', icon: FaGun},
  {key: '6-3', label: "Fusil",data: '', icon: FaGun},
  {key: '6-4', label: "Accesorios",data: '', icon: FaGun},
  ]
}
]

const PRODUCTS_CONSTANTS = {
    CATEGORIA:[
      {id: '0', name: "TODO", description: 'Ropa para hombes y mujer', icon: <img src={IcWorld} width={35} />},
      {id: '1', name: "ROPA", description: 'Ropa para hombes y mujer', icon: <PiShirtFoldedDuotone size={35} />},
      {id: '2', name: "JUGUETES",description: 'Juguetes para niños y niñas', icon: <img src={IcToy} width={35} />},
      {id: '3', name: "ACCESORIOS",description: 'Juguetes para niños y niñas', icon: <img src={IcGlasses} width={35}/>},
      {id: '4', name: "ELECTRÓNICA",description: 'Juguetes para niños y niñas', icon:<img src={IcSmart} width={35}/>},
      {id: '5', name: "CALZADO",description: 'Calzado para h y m', icon:<img src={IcHeel} width={35} />},
      {id: '6', name: "HIDROGEL",description: '', icon:<img src={IcGun} width={35} />}, 
    ],
    SUBCATEGORIA:[
        {id: '1', name: "DEPORTIVA", description: '', icon: FaFootballBall},
        {id: '2', name: "NIÑOS",description: '', icon: LiaGamepadSolid},
        {id: '3', name: "HOMBRE",description: '', icon: FaUserNinja},
        {id: '4', name: "MUJER",description: '', icon: FaFemale},
        {id: '5', name: "UNISEX",description: '', icon: FaHandsHelping},
        {id: '6', name: "HIDROGEL",description: '', icon: FaGun},
    ],
    SENSITIVE:[
      {id:'1', name: "SI"},
      {id:'2', name: "NO"},
    ],
}

export default PRODUCTS_CONSTANTS;