import { Dialog } from "primereact/dialog"
import { useContext, useEffect, useState } from "react";
import Ecommercecontext from "../../../../../EcommerceContext";
import { IoIosClose } from "react-icons/io";
import AuthContext from "../../../../../AuthContext";

import CheckoutFormComponent from "./CheckoutForm";
import CartDialogTitleComponent from "./CartDialogTitle";
import ShippingOptionsComponent from "./ShippingOptions";
import CartResumeComponent from "./CartResume";
import Cartforgetcomponent from "./CartForget";
import CartTotalCalcComponent from "./CartTotalCalc";


export const CartDialog = ({ visible, setVisible }) => {
    
    const authContext = useContext(AuthContext)
    const ecommerceContext = useContext(Ecommercecontext)
    const [cartDataComponent, setCartDataComponent] = useState({
        cartProducts: [],
        formData: {},
        shipping_type: 0,
        number: 0,
        shipping: 0,
        totalProducts: 0,
        total: 0,
        priceShipping: 200,
        limitFreeShipping: 2100,
    })
    const groupCartData = (cartData) => {
        // Initialize variables to store grouped data
        let totalProducts = 0;
        let totalPrice = 0;
        let cart = [];

        // Iterate over the cart data
        for (const item of cartData) {
            const product = item.product;
            const selectedVar = item.selectedVar;
            const selectedVariation = product.variaciones[selectedVar];

            // Update total products count
            totalProducts++;

            // Calculate total price for this item
            const itemTotalPrice = (selectedVariation.precio_sugerido || product.precio_sugerido) * 1; // Assuming 'number' is always 1

            // Update total price
            totalPrice += itemTotalPrice;

            // Find or create an entry for the current product in the cart array
            const cartItem = cart.find((cartItem) => cartItem.id_product === product.id);

            if (cartItem) {
                // Product already exists in the cart, update quantity and total price
                cartItem.number++;
                cartItem.total_price += itemTotalPrice;
            } else {
                // New product, add it to the cart array with appropriate details
                cart.push({
                    id_product: product.id,
                    name_product: product.nombre,
                    name_variation: selectedVariation.nombre || 'Variación sin nombre', // Assuming 'name' exists in 'selectedVariation'
                    price_var: itemTotalPrice,
                    photos: product.photos,
                    number: 1,
                    total_price: itemTotalPrice,
                });
            }
        }

        // Return the final grouped data object
        return {
            total_products: totalProducts,
            total_price: totalPrice,
            cart: cart,
        };
    }

    useEffect(() => {
        const ecommCart = ecommerceContext?.ecommerceContextData?.cart
        if (ecommCart && ecommCart.length > 0) {
            const cartData = groupCartData(ecommCart)
            setCartDataComponent({
                ...cartDataComponent,
                cartProducts: cartData.cart,
                number: cartData.total_products,
                totalProducts: cartData.total_price
            })
        }
    }, [ecommerceContext.ecommerceContextData.cart, visible])

    return (
        <Dialog position="bottom" className="dialog-header-hide" header={null} visible={visible} style={{ width: authContext.isMobile ? '100vw' : '50vw' }}
            onHide={() => setVisible(false)}>
            <div className="flex flex-col flex-wrap  font1 mt-2 relative">
                <div className="flex absolute top-0 right-0
                hover:scale-110 duration-500 ease-out" onClick={() => setVisible(false)}>
                    <IoIosClose size={40}/>
                </div>
                {/* TITULO */}
                    <CartDialogTitleComponent />
                
                {/* OPCIONES DE ENVIO */}
                <div className="flex flex-col px-2 bg-neutral-200 py-3 gap-2">
                    <ShippingOptionsComponent cartDataComponent={cartDataComponent} setCartDataComponent={setCartDataComponent} />
                </div>

                {/* DATOS DE CONTACTO */}
                <CheckoutFormComponent />

                <div className="flex flex-col bg-neutral-200 w-full mb-2">
                    {/*  RESUMEN DE PRODUCTOS */}
                    <div className="flex px-2 py-2 self-center">
                        <div className="text-base font-semibold border-2 border-neutral-500 px-1 rounded bg-white">RESUMEN DE PEDIDO</div>
                    </div>
                    <div className="flex w-full">

                        <CartResumeComponent cartDataComponent={cartDataComponent} />

                        <div className="flex flex-col w-1/2 px-2 justify-between gap-2">
                            <div className="flex flex-col w-full gap-2">
                                <Cartforgetcomponent 
                                    cartDataComponent={cartDataComponent}
                                    setCartDataComponent={setCartDataComponent}
                                />
                                <CartTotalCalcComponent 
                                    cartDataComponent={cartDataComponent}
                                />
                            </div>
                            <div className="flex w-full">
                                <div className="border-2 border-cyan-700 px-1 rounded w-full text-center text-xl font-semibold">PAGAR</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}