import { Route, Routes } from "react-router-dom";
import { HomeGelsoftMX } from "../../../pages/public/gelsoftmx/home";
import { CartGelsoftMX } from "../../../pages/public/gelsoftmx/Cart";

function PublicBody() {
  return (
    <div className="flex flex-col bg-neutral-200 min-h-screen">
        <Routes>
          <Route path="/" element={<HomeGelsoftMX />} />
          <Route path="/cart" element={<CartGelsoftMX />} />
        </Routes>
    </div>
  );
}

export default PublicBody;
