import { useEffect, useState } from 'react';
import { FaMinus, FaPlus } from "react-icons/fa6";
import { IoIosClose } from 'react-icons/io';
import { StringToMXN } from '../../../../../assets/utils/currency.utils';
 
const CartResumeComponent = (props) => {
    const {cartDataComponent , setCartDataComponent} = props;
    const [state, setState] = useState({});

    const removeProductFromCart = () => {

    }
    return (
        <>
             <div className="flex flex-col  overflow-y-scroll overflow-x-hidden
                        max-h-[14rem] pt-2
                         items-center w-1/2 px-2  gap-2">
                            {cartDataComponent.cartProducts.map((product) => {
                                return (
                                    <div className="flex gap-2  w-full">
                                        <div className="flex flex-col relative">
                                            <img className="max-w-[3rem] rounded" src={product.photos[0]}></img>
                                            <div className="absolute top-[-.5rem] right-[-.3rem] px-1 rounded bg-black text-sm font-semibold text-white">
                                                {product.number}
                                            </div>
                                        </div>
                                        <div className="flex flex-col relative w-full">
                                            <div className="flex absolute top-0 right-0
                                            hover:scale-110 duration-500 ease-out text-red-700" >
                                                <IoIosClose size={25} onClick={() => removeProductFromCart()}  />
                                            </div>
                                            <div className="flex absolute bottom-2 right-0
                                             gap-2" >
                                                <div className="hover:scale-110 duration-500 ease-out text-red-500" onClick={() => removeProductFromCart()}>
                                                    <FaMinus size={17}/>
                                                </div>
                                                <div className="hover:scale-110 duration-500 ease-out" onClick={() => removeProductFromCart()}>
                                                    <FaPlus size={17}/>
                                                </div>
                                                
                                            </div>

                                            <div className="text-ellipsis truncate">{product.name_product}</div>
                                            <div className="flex mt-[-.2rem] ">
                                                <div className="text-xs bold bg-emerald-800 rounded px-1 text-white">
                                                    {product.name_variation}
                                                </div>
                                            </div>
                                            <div className="small mt-[-.2rem] font-semibold">{StringToMXN(product.total_price || "0.00")} MXN</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
        </>
    );
}
 
export default CartResumeComponent;