import IcLogo from '../../../../../assets/imgs/gelsoftmx/DISEÑO_TRANSPARENTE.png'
export const AdHome = ({ title, subtitle}) => {
    return (
        <div className="w-full bg-black px-3 py-2 overflow-hidden relative" style={{height:"5rem"}}>
                    <div className="absolute" style={{top:"-3.5rem", left:"-3rem"}}>
                        <img id="img-logo-gelsoftmx" style={{opacity:".5"}} src={IcLogo} width={200}/>
                    </div>
                    <div className="flex flex-col text-neutral-300 items-center ">
                        <div className="font3 text-3xl text-neutral-100 z-10">{title}</div>
                        <div className="font4 text-4xl" style={{marginTop:"-.5rem"}}>{subtitle}</div>
                    </div>
                </div>
    )
}