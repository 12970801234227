
import React from 'react'; 
import { Menu } from 'primereact/menu';
import { Badge } from 'primereact/badge';
import { TiCogOutline } from "react-icons/ti";


export default function MenuProductCardTemplate({ menuItems, setVisible , product}) {
    const itemRenderer = (item) => {
        return <div className='p-menuitem-content'>
            <div className="flex align-items-center p-menuitem-link my-1 font1 text-xl" onClick={()=>{
                setVisible(false)
                item.onClick()
                }}>
                {item.icon}
                <span className="mx-2">{item.label}</span>
                {item.badge && <Badge className="ml-auto" value={item.badge} />}
                {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
            </div>
        </div>
    };
    let items = [
        {
            template: () => {
                return (
                    <div className='flex flex-col font1 text-center justify-center font1 text-2xl' >
                        <div>Opciones</div>
                        <div className='hr-style1 h-2'></div>
                        
                    </div>
                );
            }
        },
        {
            separator: true
        },
        {
            items: menuItems.map( mi => {
                return{
                    template: itemRenderer(mi)
                }
            })
        },
        {
            template: () => <div className='hr-style1 h-2 mt-1'></div>
        },
        {
            template: (item, options) => {
                return (
                    <div className='flex font1 gap-2 mb-2'>
                        <div className='flex items-center'>
                            <TiCogOutline size={26}/>
                        </div>
                        <div className='flex flex-col'>
                            <div className="">{product.nombre}</div>
                            <div className="bold text-xs" style={{marginTop:"-.4rem"}}>Opciones del producto</div>
                        </div>
                    </div>
                );
            }
        }
    ];

    return (
        <div className="card flex justify-content-center px-3">
            <Menu model={items} className="w-full px-3" style={{zoom:"1.1"}} />
        </div>
    )
}
        