import { CutomForm } from "../../utilities/form/CutomForm"
import IcProductRed from "../../../assets/icons/discount-price.svg"
import { ReturnProductsFormFields } from "./js/FormFields"
import { ProductForm } from "../../productos/ProductForm"
import { useContext } from "react"
import AuthContext from "../../../AuthContext"

export const ProductsForm = ({ obj, setObj, aceptFn, deleteFn}) => {
    const authContext = useContext(AuthContext)
    return (
        <>
            <CutomForm 
                title={"Formulario de Productos"}
                subtitle={"Agrega / Edita / Elimina algun producto"}
                icon={<img  src={IcProductRed}  width={30}/>}
                formFields={ReturnProductsFormFields(obj, authContext.isMobile)}
                obj={obj}
                setObj={setObj}
                aceptFn={aceptFn}
                deleteFn={deleteFn}
                RenderExtraComponent={ProductForm}
            />
        </>
    )
}