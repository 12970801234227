import { Dialog } from "primereact/dialog"
import { useContext, useEffect, useState } from "react";
import { CiDeliveryTruck } from "react-icons/ci";
import { FaCheck, FaRegSadTear } from "react-icons/fa";
import { IoTicketOutline } from "react-icons/io5";
import { TbShoppingBagPlus } from "react-icons/tb";
import AuthContext from "../../../../../AuthContext";
import Ecommercecontext from "../../../../../EcommerceContext";

export const ProductDialog = ({product , visible ,setVisible}) => {
    
    const formateador = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
    });
    const [componentData, setComponentData] = useState({
        selectedVar: 0
    })
    const ecommerceContext = useContext(Ecommercecontext)
    
    const getColor = (variation) => {
        // debugger
        if(!variation){return {}}
        let precio = 0
        if(variation.precio_sugerido){
            precio = variation.precio_sugerido
        }else if(product.precio_sugerido){
            precio = product.precio_sugerido
        }
        const pcolor = { 
            stock: variation.stock ? variation.stock : 0, 
            className: 'bg-rose-700/90 text-white' ,
            text : 'AGOTADO'  ,
            icon : <FaRegSadTear size={25} className="mx-1"/>,
            photos: variation.photos | [],
            precio_sugerido: precio 
        }

        if (pcolor.stock > 0) { 
            pcolor.className = 'bg-emerald-700 text-white'
            pcolor.text = 'EN STOCK'  
            pcolor.icon = <FaCheck size={17} className="mx-1"/>
        } else if (product.isComming) { 
            pcolor.className = 'bg-orange-700 text-white' 
            pcolor.text = 'EN CAMIN0'  
            pcolor.icon = <CiDeliveryTruck size={25} className="mx-1"/>
        }else if (product.preventa) {
            pcolor.className = 'bg-black text-white' 
            pcolor.text = 'PREVENTA'  
            pcolor.icon = <IoTicketOutline size={25} className="mx-1"/>
        }

        return pcolor
    }
    const [pcolor , setPColor] = useState({})
    const [srcImgs , setSrcImgs] = useState("")

    useEffect( () => {
        if(product.variaciones){
            setPColor( (product.variaciones) ? getColor(product.variaciones[componentData.selectedVar]) :  getColor(product.variaciones[0]) )
            setSrcImgs(pcolor.photos?.length > 0 ? pcolor.photos[0] : (
                (product.photos) ? product.photos[0] :''
            ) 
            );
        }
    }, [product, componentData.selectedVar])

    const addProduct = () =>{
        const aux = ecommerceContext.ecommerceContextData.cart
        aux.push({
            product,
            selectedVar: componentData.selectedVar
        })
        ecommerceContext.setEcommerceContextData({
            ...ecommerceContext.ecommerceContextData,
            cart: aux
        })
        ecommerceContext.toast.current.show({severity:"success", summary:"Agragado al carrito de compra"})

    }
    return (
        <Dialog position="bottom" className="sm:w-[80%] lg:w-[40%] sm:max-w-[50%] lg:max-w-[40%]" header={null} visible={visible} 
        onHide={() => setVisible(false)}>
            <div className="flex flex-col w-full">
                <div className="flex justify-center items-center">
                    { product &&
                    <div className="flex relative font7 ">
                        <div className="flex justify-center w-full">
                        <img className="max-w-[70%]"  src={srcImgs} ></img>
                        </div>
                        <div className="flex absolute w-full h-full text-[4rem] justify-center items-center text-neutral-700/40">
                            GELSOFTMX
                        </div>
                        <div className=" bg-black font1  absolute text-[1.3rem] text-white px-2 py-0 bottom-[10px] right-[0px]">
                            {formateador.format(parseFloat(  pcolor.precio_sugerido | 0 ))}
                        </div>
                        <div className="font7 text-black/90  absolute text-[1.3rem]  px-2 py-0 top-[10px] justify-center w-full flex">
                            GELSOFTMX
                        </div>
                        <div className={`${pcolor.className} flex gap-1 items-center scale-90 font1 absolute text-[1.3rem]  px-2 py-0 bottom-[10px] left-[-5px] `}>
                            {pcolor.text} {pcolor.icon }
                        </div>
                        { product.preventa &&
                            <div className={`${pcolor.className} flex gap-1 items-center scale-90 font1 absolute text-[1.3rem]  px-2 py-0 bottom-[3rem] left-[-5px] `}>
                               - {product.preventa_day} -
                            </div>
                        }
                    </div>
                    }
                </div>
                <div className="flex flex-col font1 bg-neutral-100">
                    <div className="px-2 py-1 flex justify-end">
                        {/* <div className={`${pcolor.className} p-1 border-1 rounded flex gap-1 items-center scale-90 `}>
                            {pcolor.text} <PiFire />
                        </div> */}
                        <div
                        onClick={() => { 
                            if(pcolor.stock > 0)
                            addProduct() 
                            else
                            ecommerceContext.toast.current.show({severity:"error", summary:"Producto agotado"})

                        }}
                        className={`py-1 px-2 border-2 text-black flex gap-1 rounded font-semibold
                        border-black/90 items-center hover:scale-105 duration-300 ease-in`}>
                            COMPRAR <TbShoppingBagPlus size={20} className="mx-1"/>
                        </div>
                    </div>
                    {/* TITULO */}
                    <div className="px-2 pt-0 pb-1 flex flex-col leading-[.9] bg-neutral-100">
                        <div className="text-2xl font-semibold">{product.nombre}</div>
                        <div className="mb-1">
                                Versiónes:
                        </div>
                        <div className="flex gap-2">
                            { product?.variaciones?.map( (v, iVar) => {
                                
                                return (
                                    <div key={`pd-pv-${iVar}`} 
                                    onClick={() => { setComponentData({...componentData, selectedVar: iVar}) }}
                                    className={`p-1 border-1 rounded hover:scale-105 duration-300 
                                    ${iVar == componentData.selectedVar? 'bg-neutral-800 text-neutral-100 boder-black': 'border-neutral-500'} `}>
                                        {v.nombre}
                                    </div>
                                )
                            })

                            }
                        </div>
                    </div>
                    {/* DESCRIPCIÓN */}
                    <div className="whitespace-pre-line leading-[1.1] bg-neutral-200 p-2 max-h-[15rem] overflow-y-scroll mt-1">
                    {product.text_detail}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}