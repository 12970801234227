
import { AdHome } from "../../../components/public/gelsoftmx/components/home/AdHome";
import { CardsListHome } from "../../../components/public/gelsoftmx/components/home/CardsListHome";
import { FiltersHome } from "../../../components/public/gelsoftmx/components/home/FiltersHome";
import { FaArrowRight, FaRegCreditCard, FaWhatsapp } from "react-icons/fa";
import { IoLogoFacebook, IoPricetags } from "react-icons/io5";
import { GiPistolGun } from "react-icons/gi";

import { GrSend } from "react-icons/gr";
import { StepperHomeTemplate } from "../../../components/public/gelsoftmx/components/home/StepperHome";
import { useContext, useEffect, useState } from "react";
import { AxiosWithoutHeader } from "../../../assets/utils/curl.utils";
import AuthContext from "../../../AuthContext";
import { Tracking } from "../../../components/public/gelsoftmx/components/home/Tracking";
import { HomeBanner } from "../../../components/public/gelsoftmx/components/home/Banner";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { PromotionProductsComponent } from "../../../components/public/gelsoftmx/components/home/PromotionProducts";

export const HomeGelsoftMX = () => {
    const authContext = useContext(AuthContext)
    const [componentData, setComponentData] = useState({
        loading: true,
        products: {
            list: [], filter: [], totalFiltered: [], loading: true
        }
    })

    useEffect( () => {
        AxiosWithoutHeader(`${authContext.host}/gelsoftmx/products`, 'GET').then(productsData => {
            const filter = obtenerPagina(productsData.data,  0 , 6 )
            setComponentData({
                loading: false,
                products: {
                    list: productsData.data, filter , totalFiltered: productsData.data, loading: false
                }
            })
        })
    }, [])
    const obtenerPagina = (datos, paginaActual, elementosPorPagina) => {
        const inicio = paginaActual * elementosPorPagina;
        const fin = inicio + elementosPorPagina;
        return datos.slice(inicio, fin);
    }
    const changePage = (event = null, list= null) => {
        const data = obtenerPagina((list)?list : componentData.products.totalFiltered, (event) ? event.page : 0 , (event) ? event.rows : 6)
        setComponentData({...componentData, products:{...componentData.products, filter: data , totalFiltered: (list)?list : componentData.products.totalFiltered }})
    }

    

    return (
        <div className="flex flex-col font1">
                <div className="flex flex-col text-2xl justify-center w-full relative">
                    {/* VID */}
                    <HomeBanner />

                    <div className="flex max-[600px]:flex-col">
                        {/* INFORMACION 1 */}
                        <div className="flex p-2 my-2  justify-between gap-2
                            min-[600px]:w-[60%]
                        ">
                            <div className="flex flex-col text-base text-center w-[70%]">
                                
                                <div className="mb-2">
                                    Por el momento todas las ventas son desde: <br/>
                                    📱 WhatsApp +52 55 2683 7289 📱
                                </div>
                                <div className="flex justify-center mt-1 gap-2">
                                    <div onClick={() => { window.open('https://wa.me/525526837289') }} className="
                                    hover:scale-105 duration-300
                                    flex gap-1 items-center px-2 py-1 border-2 border-emerald-500 rounded font-semibold">
                                    <FaWhatsapp/> WhatsApp
                                    </div>
                                    <div onClick={() => { window.open('https://www.facebook.com/GelsoftMexico?locale=es_LA')}} className="
                                    hover:scale-105 duration-300
                                    flex gap-1 items-center px-2 py-1 border-2 border-cyan-500 rounded font-semibold">
                                    <IoLogoFacebook /> Facebook
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center gap-1 w-[30%]" >
                                <div className="flex gap-1 items-center">
                                    <div className="rounded-sm ring-emerald-500  ring-offset-1 ring-2" >
                                        <GiPistolGun className="text-emerald-600" size={17} />
                                    </div>
                                    <FaArrowRight size={15}/>
                                    <div className="text-base font-semibold">Disponible</div>
                                </div>
                                <div className="flex gap-1 items-center">
                                    <div className="rounded-sm ring-rose-700/60  ring-offset-1 ring-2" >
                                        <GiPistolGun className="text-rose-700" size={17} />
                                    </div>
                                    <FaArrowRight size={15}/>
                                    <div className="text-base font-semibold">Agotado</div>
                                </div>
                                <div className="flex gap-1 items-center">
                                    <div className="rounded-sm ring-orange-500/60  ring-offset-1 ring-2" >
                                        <GiPistolGun className="text-orange-500" size={17} />
                                    </div>
                                    <FaArrowRight size={15}/>
                                    <div className="text-base font-semibold">Por llegar</div>
                                </div>
                                <div className="flex gap-1 items-center">
                                    <div className="rounded-sm ring-neutral-900/60  ring-offset-1 ring-2" >
                                        <GiPistolGun className="text-neutral-900" size={17} />
                                    </div>
                                    <FaArrowRight size={15}/>
                                    <div className="text-base font-semibold">Preventa</div>
                                </div>
                            </div>
                        </div>

                        {/* TRACKING */}
                        <div className="flex min-[600px]:w-[40%]">

                        <Tracking />
                        </div>
                    </div>

                    {/* DETAIL TRACKING SHIPPMENT */}
                    <div className="flex flex-col bg-neutral-100 hidden">
                        <div className="flex font-semibold text-[1.3rem] justify-center">Llegada de nuevos productos</div>
                        <div className="flex justify-between text-sm px-3 my-2">
                            <div className="flex items-center">
                                <div>Fecha inicio </div>
                                <FaArrowRight className="mx-1 text-neutral-600" size={15}/>
                                <div>10/11/2023</div>
                            </div>
                            <div className="flex flex-col ">
                                <div className="flex">
                                    <div>Fecha estimada </div>
                                    <FaArrowRight className="mx-1 text-neutral-600" size={15}/>
                                    <div>26 Enero 2024</div>
                                </div>
                                <div className="flex">
                                    <div>Fecha llegada </div>
                                    <FaArrowRight className="mx-1 text-neutral-600" size={15}/>
                                    <div className="text-rose-600 font-semibold">Atraso</div>
                                </div>
                            
                            </div>
                        </div>
                        <StepperHomeTemplate />
                    </div>

                    <div className="flex bg-neutral-200 gap-2 text-base justify-between px-3 py-3 text-center text-neutral-700 font1">
                        <div className="flex flex-col items-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer">
                            <div><GrSend size={30} /></div>
                            <div className="">Envio a todo México</div>
                        </div>
                        <div className="flex flex-col items-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer">
                            <div><FaRegCreditCard size={30} /></div>
                            <div className="">Crédito y Débito</div>
                        </div>
                        <div className="flex flex-col items-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer">
                            <div><IoPricetags size={30} /></div>
                            <div className="">Excelentes Precios</div>
                        </div>
                        <div className="flex flex-col items-center hover:scale-125 transition duration-500 ease-in-out cursor-pointer">
                            <div><IoPricetags size={30} /></div>
                            <div className="">Mercado Pago</div>
                        </div>
                    </div>
                    <AdHome title={"- Promociones Junio-"} subtitle={"Envío inmediato"}/>
                    <PromotionProductsComponent />

                    {/* AVISOS */}
                    <AdHome title={"- Nuevo -"} subtitle={"catálogo digital"}/>
                    {/* FILTROS */}
                    <div className="my-2">
                    <FiltersHome changePage={changePage}  fatherData={componentData} setFatherData={setComponentData} />
                    </div>

                    {/* PRODUCTOS */}
                    <CardsListHome totalRecords={componentData.products.totalFiltered.length }
                    changePage={changePage} products={componentData.products.filter} loading={componentData.products.loading}/>
                    {/* PREVENTAS */}

                    {/*  */}
                    
                </div>

        </ div>
    )
} 