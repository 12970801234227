
import { RequestList } from "./RequestList"
import { OtDetail } from "../ot/OtDetail"
import { useState } from "react"
 
export const RequestLists = ({requests, exportToExcel}) => {
    const [visibleDetail, setVisibleDetail] = useState(false)
    const [selectedRequest, setSelectedRequest] = useState({})

    const openDetail = (request) => {
        setSelectedRequest(request)
        setVisibleDetail(true)
    }
    
    return (
        <>
            <OtDetail visible={visibleDetail} setVisible={setVisibleDetail} data={selectedRequest} setData={setSelectedRequest}/>
            <div className="flex flex-col w-full">  
                {requests.map( request => {
                    return <RequestList exportToExcel={exportToExcel} request={request} openDetail={openDetail}/>
                } )}
            </ div>
        </>
    )
}