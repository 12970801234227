import { useEffect, useState } from "react"
import { FaPause, FaPlay } from "react-icons/fa6"

export const HomeBanner = ({}) => {
    const [componentData, setComponentData] = useState({
        loading: true,
        videoDiv: null,
        isPlaying: false,
        videoSrc: 'https://firebasestorage.googleapis.com/v0/b/ugarinventario.appspot.com/o/lowcost%2Fpublic%2F2024-02-07%2015-26-21.mp4?alt=media&token=3d668f9d-a45c-4b1a-9708-426773b56917'
    })

    const playVideo = () => {
        componentData.videoDiv.play()
        setComponentData({...componentData, isPlaying: true})
    }
    const pauseVideo = () => {
        componentData.videoDiv.pause()
        setComponentData({...componentData, isPlaying: false})
    }
    useEffect( () => {
        var video = document.getElementById("myVideo");
        setComponentData({...componentData, videoDiv: video})
        video.volume = 0.1;
    }, [])

    
    return (
        <>
        <div className="w-screen flex justify-center relative h-[25rem] overflow-hidden items-end bg-black">
                    <div className="flex flex-col mb-4 bg-black w-full text-white text-center opacity[1] z-[1]">
                        <div className="text-2xl font-semibold">Bienvenido a GelsoftMX 🎉</div>
                        <div style={{marginTop:"-6px"}}>Líder en réplicas de hidrogel en México</div>
                    </div>
                    { !componentData.isPlaying ?
                        <div className="flex w-full justify-center items-center h-full absolute z-[1] cursor-pointer" onClick={playVideo}>
                            <FaPlay size={40} className=" mb-[4rem] text-neutral-200/80" />
                        </div>:
                        <div className="flex w-full justify-center items-center h-full absolute z-[1] cursor-pointer" onClick={pauseVideo}>
                            <FaPause size={40} className=" mb-[4rem] text-neutral-200/10" />
                        </div>
                    }
                    <video id="myVideo" loop className="max-w-[none] h-100% opacity-[.7] top-[-8rem] absolute" src={componentData.videoSrc}>
                        Tu navegador no admite la etiqueta de video.
                    </video>
                </div>
        </>
    )
}