import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Header from "./Header";
import SideMenu from "./SideMenu";

import "primereact/resources/themes/lara-dark-indigo/theme.css";

import Inventory from "../../../pages/Inventory";
import PurchaseDetails from "../../../pages/PurchaseDetails";
import Sales from "../../../pages/Sales";
import Store from "../../../pages/Store";
import { ShipmentsPage } from "../../../pages/registered/Shipments";
import Login from "../../../pages/Login";

function Layout() {
  return (
    <>
      <div itemScope>
        <meta name="HandheldFriendly" content="true" />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Low Cost App"
        />
        <link href="logo-bg.png" />
        <link rel="manifest" href="manifest.json" />
        <title>Low Cost</title> 
      </div>

      <div className="md:h-16">
        <Header />
      </div>
      <div className="grid grid-cols-12 bg-gray-100 items-baseline">
        <div className="col-span-1 h-screen sticky top-0 hidden lg:flex">
          <SideMenu />
        </div>
        <Routes >
            {/* <Route path="/register" element={<Register />} /> */}
            <Route index element={<Inventory />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/purchase-details" element={<PurchaseDetails />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/manage-store" element={<Store />} />
            <Route path="/shipments" element={<ShipmentsPage />} />
        </Routes>
      </div>
    </>
  );
}

export default Layout;
