
import React, { useState } from "react";
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { GiPistolGun } from "react-icons/gi";

export default function PaginatorComponent({ changePage, totalRecords }) {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(6);

    const onPageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
        // debugger
        changePage(e)
    };

    const template = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        Items per page:{' '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span className="font1 font-semibold ml-3" style={{ color: 'var(--text-color)', textAlign: 'center' }}>
                 &#128195; Página {options.currentPage} 
                    <br /> 
                    Mostrando {options.first} - {options.last} de {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className="flex justify-end mt-3 items-center px-3 ">
          
            <Paginator 
            template={template} 
            first={first} 
            rows={rows} 
            totalRecords={totalRecords} 
            onPageChange={(e) => onPageChange(e)} 
            className="justify-content-end border-2 border-black" />
        </div>
    )
}
