import { useEffect, useState } from 'react';
import { StringToMXN } from '../../../../../assets/utils/currency.utils';

const CartTotalCalcComponent = (props) => {
    const { cartDataComponent } = props;
    const [state, setState] = useState({
    });
    useEffect(() => {
    }, [])
    const sumTotal = () => {
        const shipping = (cartDataComponent.shipping_type == 0 && cartDataComponent.totalProducts < cartDataComponent.limitFreeShipping ) ? cartDataComponent.priceShipping : 0 
        return shipping + cartDataComponent.totalProducts
    }
    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-wrap justify-between">
                    <div className="flex font-semibold">Productos: </div>
                    <div className="flex">{StringToMXN(cartDataComponent.totalProducts)}</div>
                </div>
                <div className="flex flex-wrap justify-between">
                    <div className="flex font-semibold">Envío: </div>
                    <div className="flex">{StringToMXN(
                        (cartDataComponent.shipping_type == 0 && cartDataComponent.totalProducts < cartDataComponent.limitFreeShipping) ?
                            cartDataComponent.priceShipping : 0
                    )}
                    </div>
                </div>
                <div className="flex flex-wrap justify-between">
                    <div className="flex font-semibold">Total: </div>
                    <div className="flex">{
                        StringToMXN(sumTotal())
                    } MXN</div>
                </div>
            </div>
        </>
    );
}

export default CartTotalCalcComponent;