export const StringToMXN = (value) => {
  const preprice = parseFloat(value)
  const price = preprice.toLocaleString('es-MX', {
    style: 'currency',
    currency: 'MXN',
  })
    return price;
}

export const fetchWithTokenHeader = async (url, method, body) => {
  try {
    const token = localStorage.getItem("token");
    let response = await fetch(`${url}`, {
      method,
      headers: {
        'authorization' : token
      },
      body
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(" Error en consulta a backend ")
  }
}