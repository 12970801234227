import { Checkbox } from "primereact/checkbox";
import { SlOptionsVertical } from "react-icons/sl";
import { FaArrowRight, FaShippingFast } from "react-icons/fa"
import { useState } from "react";
import { SiMicrosoftexcel } from "react-icons/si";


const getQty = (details) => details.reduce((total, detail) => {
    const qty = (detail.variaciones.reduce((t,v)=> t+v.cantidad,0))
    return total + qty
} , 0);
const getVarQty = (detail) => detail.variaciones.reduce( (t,v) => t + v.cantidad, 0)
const getGain = (details) => details.reduce((total, detail) => total + (getVarQty(detail) * parseFloat(detail.precio_sugerido)), 0);
const costo = (details) => details.reduce((total,detail) => (total + (getVarQty(detail) * parseFloat(detail.precio) ) ), 0)
const getGainPorc = (details , otData) => {
    const [valueCosto, valueGain] = [parseFloat(otData.totalFinal | 0) | otData.totalMxn, getGain(details)]
    const porc = ( valueGain * 100) / valueCosto
    return porc
};

export const RequestList = ({request, openDetail, exportToExcel}) => {
    const [checked, setChecked] = useState(false)
    let color = 'gray';
    switch(request.estado){
        case "1":case 1: color="#B2BABB"; break;
        case "2":case 2: color="#A2D9CE"; break;
        case "3":case 3: color="#45B39D"; break;
    }
    const opcionesFormato = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };


    return (
        <div className={`flex w-full p-1 border-b-2 border-neutral-200 font1`}>
            <div className="flex flex-col w-1/4 justify-center">
                <div className="flex text-xs">{request.details.length} PRODUCTOS</div>
                <div className="flex text-xs">{getQty(request.details)} PIEZAS</div>
                <div className="flex pl-2" ><FaShippingFast  size={25} color={color}/></div>
                
            </div>
            <div className="flex flex-col w-2/4">
                <div className="border-b-2 border-neutral-300">{request.name} - {request.description}</div>

                <div className="flex flex-col pr-2 text-xs">
                    <div className="flex justify-between items-center">
                        <div className="flex">Total Estimado</div> 
                        {/* <FaArrowRight className="mx-1" /> */}
                        <div className="flex flex-col text-neutral-500">
                            <div className="flex">
                            {`MXN: ${parseFloat(costo(request.details)).toLocaleString("es-MX",opcionesFormato)}`}
                            </div>
                        </div> 

                    </div>
                    <div className="flex justify-between items-center mb-1">
                        <div className="flex">Total Real</div> 
                        {/* <FaArrowRight className="mx-1" /> */}
                        <div className="flex flex-col text-neutral-500">
                            <div className="flex">
                            {`MXN: ${parseFloat(request.totalFinal | 0).toLocaleString('es-MX', opcionesFormato)}`}
                            </div>
                        </div> 

                    </div>
                    {/*  */}
                    <div className="flex justify-between items-center">
                        <div className="flex">Ganancia</div> 
                        {/* <FaArrowRight className="mx-1" /> */}
                        <div className="flex flex-col text-neutral-500">
                            <div className="flex">
                            {`$ ${getGain(request.details).toLocaleString('es-MX', opcionesFormato) }`}
                            </div>
                        </div> 
                        {/* <FaArrowRight className="mx-1" /> */}
                        <div className="flex flex-col text-neutral-500">
                            <div className="flex border-2 rounded-md px-1">
                            {`x ${(getGainPorc(request.details, request) / 100).toFixed(2) }`}
                            </div>
                        </div> 


                    </div>
                </div>
            </div>
            <div className="flex gap-2 w-1/4 justify-center items-center">
                <div className="flex transition hover:scale-110 duration-1000">
                    <Checkbox className="checkbox-neutral" onChange={e => setChecked(e.checked)} checked={checked}/>
                </div>

                <div className="flex flex-col cursor-pointer p-1 rounded items-center 
                transition hover:scale-110 hover:text-gray-600 duration-1000
                " onClick={() => openDetail(request)}>
                    <SlOptionsVertical size={18} />
                    <div className="text-xs small"> Detalle</div>
                </div>
                <div className="flex flex-col cursor-pointer p-1 rounded 
                transition hover:scale-110 hover:text-gray-600 duration-1000
                " onClick={() => exportToExcel(request)}>
                    <SiMicrosoftexcel size={18} />
                    <div className="text-xs small"> Excel</div>
                </div>
            </div>
        </ div>
    )
}