import { useContext } from "react"
import AuthContext from "../../../AuthContext";
import { CutomForm } from "../../utilities/form/CutomForm";
import { ReturnShipmentFormFields } from "./js/utilities";

import IcSend from "../../../assets/icons/send-alt.svg"
        
export const ShipmentForm = ({ shipmentData , setShipmentData , aceptFn , deleteFn}) => {
    const authContext = useContext(AuthContext)
    
    return (
        <>
            <CutomForm 
                title={"Formulario de envíos"}
                subtitle={"Agrega / Edita / Elimina algun envío"}
                icon={<img  src={IcSend}  width={30}/>}
                formFields={ReturnShipmentFormFields(shipmentData, authContext.isMobile)}
                obj={shipmentData}
                setObj={setShipmentData}
                aceptFn={aceptFn}
                deleteFn={deleteFn}
                
            />
        </>
    )
}