import { useEffect, useState } from "react"
import IcAk1 from '../../../../../assets/imgs/gelsoftmx/replicas/ak_47_m_2.png'
import { PromotionProductCardComponent } from "./PromotionProductCard"

import IcAK47 from '../../../../../assets/icons/gun-ak.svg'
import IcPeq15 from '../../../../../assets/imgs/gelsoftmx/peq15.png'

export const PromotionProductsComponent = ({}) => {
    const [componentData, setComponentData] = useState({
        loading: true,
        videoDiv: null,
        isPlaying: false,
    })

    return (
        <>
        <div className="flex gap-2 m-2">
            <div className="flex flex-col gap-2 w-full">
                <div className="flex">
                    <div className="flex">
                        <img src={IcAK47} width={45} />
                    </div>
                    <div className="flex text-[2rem]">
                    Réplicas ({3})
                    </div>
                </div>
                <div className="flex overflow-x-scroll gap-2">
                    {   [
                        {img:'',name:'Ak47 Series Black',price:'750.00'},
                        {img:'',name:'BingFeng P90 ',price:'1800.00'},
                        {img:'',name:'M16 260FPS ARENA',price:'1800.00'}
                        ].map(it =>{
                        return <PromotionProductCardComponent product={it} photosrc={IcAk1} />
                    }) }
                </div>
                <div className="text-[2rem] flex"> <img src={IcPeq15} width={55} /> Otros(3)</div>
                <div className="flex overflow-x-scroll gap-2 ">
                    {   [
                        {img:'',name:'Hidrogel Endurecido',price:'100.00'},
                        {img:'',name:'Hidrogel Normal',price:'55.00'},
                        {img:'',name:'Mira Lateral',price:'95.00'}
                        ].map(it =>{
                        return <PromotionProductCardComponent product={it} photosrc={IcAk1} />
                    }) }
                </div>
            </div>
        </div>
        </>
    )
}