export const SHIPPING_CONSTANTS = {
    STATUS:[
        {id: "1", name:"ACTIVO"},
        {id: "2", name:"EN PROCESO"},
        {id: "3", name:"ADUANAS"},
        {id: "4", name:"ENTREGA"},
        {id: "5", name:"FINALIZADO"},
    ],
    AGENTS:[
        {id: "1", name:"ANNA"},
    ]
}