import { GiPistolGun } from 'react-icons/gi'
import IcAk1 from '../../../../../assets/imgs/gelsoftmx/replicas/ak_47_m_2.png'
import { ProductDialog } from './ProductDialog';
import { useEffect, useState } from 'react';
import { HiOutlineEmojiSad } from "react-icons/hi";
import PaginatorComponent from '../../../../utilities/paginator/Paginator';

export const CardsListHome = ({ changePage, products, loading , totalRecords = 0 }) => {
    const [componentData, setComponentData] = useState({
        productDialog: {
            visible: false,
            product: {}
        }
    })
    const setVisibleProductDialog = (isVisible, product = {}) => {
        setComponentData({
            ...componentData,
            productDialog: { ...componentData.productDialog, visible: isVisible, product }
        })
    }
    const openCard = (product) => {
        setVisibleProductDialog(true, product)
    }
    return (
        <>
            <ProductDialog
                visible={componentData.productDialog.visible} setVisible={setVisibleProductDialog}
                product={componentData.productDialog.product} />
            {/* <div
                className="w-full  flex flex-col-reverse  relative
            bg-neutral-300 mt-2"
                style={{ height: "8rem" }}>
                <div className='flex justify-between bg-neutral-400'>
                    <div className='flex flex-col font7 w-full'>
                        <div className='bg-neutral-500 pl-2 text-neutral-400'>GELSOFTMX -</div>
                        <div className='bg-neutral-600 pl-2 text-neutral-300'>$750.00</div>
                        <div className='bg-neutral-700 pl-2 text-neutral-300'>AK-47 SERIES</div>
                    </div>
                    <div className='absolute overflow-hidden' style={{
                        width: "26rem", top: "-1rem", height: "10rem", right: "0rem"
                    }}>
                        <img src={IcAk1} style={{
                            transform: "scale(1) rotate(6deg) translateY(23px)", maxWidth: "none",
                            width: "30rem"
                        }} />
                    </div>
                </div>
            </div> */}

            <div className="flex flex-col">
                <div className="flex justify-center">
                    <PaginatorComponent totalRecords={totalRecords} changePage={changePage} />
                </div>
                <div className="flex flex-wrap p-2 mt-3 gap-3 justify-center">
                    {!(loading) ?
                        <>
                            {products.length > 0 ?
                                <>
                                    {products.map((product, index) => {
                                        const formateador = new Intl.NumberFormat('es-MX', {
                                            style: 'currency',
                                            currency: 'MXN',
                                        });


                                        const pcolor = product.variaciones.reduce((total, pv) => {
                                            if (pv.stock) { total.stock += parseFloat(pv.stock) }
                                            return total
                                        }, { stock: 0, divClass: 'ring-rose-700', className: 'text-rose-700/90' })

                                        if (pcolor.stock > 0) { 
                                            pcolor.divClass = 'ring-emerald-500'; 
                                            pcolor.className = 'text-emerald-600' 
                                        }
                                        else if (product.isComming) { 
                                            pcolor.divClass = 'ring-orange-500'; 
                                            pcolor.className = 'text-orange-700' 
                                        }
                                        else if (product.preventa) { 
                                            pcolor.divClass = 'ring-black'; 
                                            pcolor.className = 'text-black' 
                                        }

                                        return (
                                            <div key={`pclh-${index}`} onClick={() => openCard(product)} className="flex flex-col hover:scale-105 duration-300 max-w-[7.5rem]">
                                                <div className="flex relative">
                                                    <div className='flex absolute w-full h-full justify-center items-center cursor-pointer'>
                                                        <div className='flex font7 text-3xl text-neutral-700/40'>GELSOFTMX</div>
                                                    </div>
                                                    <img className='rounded' style={{maxHeight:"10rem"}} src={product.photos[0]} />
                                                    { product.preventa &&
                                                        <div className='flex w-full h-[1rem] bg-black rounded-b absolute bottom-[0px] text-[10px] text-white justify-center items-center'>
                                                            {(product.isComming)? 'EN CAMINO': product.preventa_day}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="flex flex-col px-1 text-xs ">
                                                    <div className='font-semibold'>{product.nombre}</div>
                                                    <div className='text-sm font-bold flex justify-between items-center relative mt-[-5px]'>
                                                        <div className='flex'>{formateador.format(parseFloat(product.precio_sugerido | 0))}</div>
                                                        <div className={`rounded-sm ${pcolor.divClass}  ring-offset-1 ring-2  mt-[-10px]`} >
                                                            <GiPistolGun className={pcolor.className} size={15} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </> :
                                <div className='flex flex-col w-full h-[10rem] justify-center items-center text-neutral-500/90'>
                                    <div className="flex">
                                        <HiOutlineEmojiSad className='' size={50} />
                                    </div>
                                    <div className="flex font1 mt-[-5px]">
                                        <div>No hay productos</div>
                                    </div>
                                </div>
                            }
                        </> :
                        <div className='flex w-full justify-center'>
                            <div className="flex flex-col">
                                <div className="flex justify-center">
                                    <div className="loader-spinner1"></div>
                                </div>
                                <div className="tf f-6 text-xl">
                                    Cargando Productos ...
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </>
    )
}