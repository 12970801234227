import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useState } from 'react'
import '../../../assets/css/products/product-filter.css'

export default function PopoverFilter({
    catalogue,
    subcatalogue,
    setfilters
}) {
    const [selected, setSelected] = useState([ [],[] ])

    const handleSelect = (type, item) => {
        const selectedCopy = [...selected];
        const index = selectedCopy[type].indexOf(item.id);
        
        if (index !== -1) {
            selectedCopy[type].splice(index, 1);
        } else {
            selectedCopy[type].push(item.id);
        }
        
        setSelected(selectedCopy);
        setfilters(selectedCopy)
    }
      
  return (
    <div className="top-16 w-full max-w-sm">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                w-full
                group inline-flex items-center rounded-md bg-black px-2 py-1 
                font-medium text-white hover:text-opacity-100 focus:outline-none 
                focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`
            }
            >
              <span>Filtro</span>
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  h-5 w-5 text-white transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-10">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                    {catalogue.map((item) => (
                      <div
                        key={item.name}
                        onClick={ () => handleSelect(0,item)}
                        className={`
                        ${(selected[0].includes(item.id)) ? 'filter-product-item-active ' : ' '}
                        -m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out 
                        hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 
                        focus-visible:ring-opacity-50
                        `}
                      >
                        <div className="invert flex h-20 w-20 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                          <item.icon aria-hidden="true" size={"2em"}/>
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="bg-gray-50 grid gap-8 p-5 lg:grid-cols-2">
                  {subcatalogue.map((item) => (
                      <a
                        key={item.name}
                        onClick={ () => handleSelect(1,item)}
                        className={`
                        ${(selected[1].includes(item.id)) ? 'filter-product-item-active ' : ' '}
                        -m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out 
                        hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 
                        focus-visible:ring-opacity-50
                        `}
                      >
                        <div className="invert flex h-20 w-20 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                          <item.icon aria-hidden="true" size={"2em"}/>
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            {item.description}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}
