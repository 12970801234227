import { Dialog } from "primereact/dialog"
import { InputText } from 'primereact/inputtext';
import { SET_VALUE_TO_STATE } from "../../../assets/utils/general.utils"
import { useContext, useState } from "react"
import AuthContext from "../../../AuthContext";
import { Button } from "primereact/button";
import { InputTextarea } from 'primereact/inputtextarea';
import { IoClose, IoTrashBinOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import ReactDatePicker from "react-datepicker";
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { FiAlertTriangle } from "react-icons/fi";

import { TreeSelect } from 'primereact/treeselect';
import { PRODUCTS_CTEGORIA } from "../../../constants/products.constants";
        


const Header = ({ title, subtitle, Icon }) => {
    return (
        <div className="font1 text-2xl font-normal flex flex-col ml-2 mb-3 p-2">
            <div className="flex">{title} {(Icon) ? Icon : null}</div>
            <div className="text-lg" style={{ marginTop: "-7px" }}>{subtitle}</div>
        </ div>
    )
}
// 
// const objReq = {
//     loading: false,
//     visibleForm: false,
//     dataForm: {

//     }
// }
export const CutomForm = ({ obj, setObj, formFields, title, subtitle, icon, aceptFn, deleteFn, RenderExtraComponent = null }) => {
    const authContext = useContext(AuthContext)
    const [dataComponent, setDataComponent] = useState({
        validForm: false, validFormText: "", action: null
    })
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setObj({
            ...obj,
            dataForm: {
                ...obj.dataForm,
                [name]: value
            }
        })
    };
    const renderInput = (field) => {
        switch (field.type) {
            case "text":
                return <InputText className="border-b-[3px] bg-neutral-100 border-neutral-400 ring-0 w-full p-1 rounded-md" name={field.name} value={field.value} onChange={handleInputChange} />;
            case "number":
                return <InputNumber inputClassName="border-b-[3px] bg-neutral-100 border-neutral-400 ring-0"
                 minFractionDigits={2} maxFractionDigits={2} min={field.min ? field.min : 0} name={field.name} value={field.value} onChange={(data) => handleInputChange({ target: { name: field.name, value: data.value } })} />
            case "textarea":
                return <InputTextarea className="border-b-[3px] bg-neutral-100 border-neutral-400 ring-0 w-full p-1 rounded" rows={field.rows ? field.rows : 2} name={field.name} value={field.value} onChange={handleInputChange} />;
            case "date":
                return <ReactDatePicker className="date-picker-react border-b-[3px] bg-neutral-100 border-neutral-400 ring-0 w-full p-1 rounded" name={field.name} selected={field.value ? new Date(field.value) : null} onChange={(date) => { handleInputChange({ target: { name: field.name, value: date } }) }} />;
            case "dropdown":
                return <Dropdown placeholder="selecciona" name={field.name} value={field.value} onChange={handleInputChange} options={field.options ? field.options : []}
                    optionLabel={field.optionLabel ? field.optionLabel : "name"} optionValue={field.optionValue ? field.optionValue : "id"} className="w-full border-b-[3px] bg-neutral-100 border-neutral-400 ring-0 py-1 pl-1" />
            case "multidropdown":
                return <MultiSelect placeholder="selecciona" name={field.name} value={field.value} onChange={handleInputChange} options={field.options ? field.options : []}
                            optionLabel={field.optionLabel ? field.optionLabel : "name"} optionValue={field.optionValue ? field.optionValue : "id"} className="w-full border-b-[3px] bg-neutral-100 border-neutral-400 ring-0 pl-1" />
            case "treeselect":
                return <TreeSelect value={field.value}  options={PRODUCTS_CTEGORIA} className="w-full border-b-[3px] bg-neutral-100 border-neutral-400 ring-0 rounded py-1"
                 onChange={(e) => handleInputChange({ target:{ value: e.value, name: field.name  }} )}  />
            default: return <>Input no encontrado</>;
        }
    }
    const validForm = () => {
        setDataComponent({ validForm: true, validFormText: "Deseas guardar el registro?", action: save })
    }
    const validDelete = () => {
        setDataComponent({ validForm: true, validFormText: "Deseas eliminar el registro?", action: deleteForm })
    }
    const deleteForm = () => {
        obj.dataForm.deletedAt = new Date()
        setDataComponent({ ...dataComponent, validForm: false })
        setObj({ ...obj, visibleForm: false })
        deleteFn(obj.dataForm)
    }
    const save = () => {
        setDataComponent({ ...dataComponent, validForm: false })
        setObj({ ...obj, visibleForm: false })
        aceptFn(obj.dataForm)
    }
    const setFormData = (value) => {
        setObj({
            ...obj,
            dataForm: value
        })
    }
    return (
        <>
            <Dialog
                header={null} position="bottom" className="mb-1 dialog-header-hide"
                visible={dataComponent.validForm}
                style={{ width: (authContext.isMobile) ? '96vw' : '50vw' }}
                onHide={() => setDataComponent({ ...dataComponent, validForm: false })}
            >
                <div className="p-2 font1 flex flex-col justify-center items-center" style={{ height: "25vh" }}>
                    <div><FiAlertTriangle size={80} /></div>
                    <div className="text-3xl">{dataComponent.validFormText}</div>
                    <div className="flex mt-3 gap-4">
                        <Button onClick={() => setDataComponent({ ...dataComponent, validForm: false })} className="p-1 border-2 rounded border-plate-400 text-plate-400" > Cancelar <IoClose size={20} className="ml-1" /></Button>
                        <Button onClick={dataComponent.action} className="p-1 border-2 rounded border-emerald-400 text-emerald-400" > Aceptar <FaCheck className="ml-1" /> </Button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                header={<Header title={title} subtitle={subtitle} Icon={icon} />}
                style={{ width: (authContext.isMobile) ? '96vw' : '50vw' }}
                position="bottom"
                className="mb-1"
                onHide={() => SET_VALUE_TO_STATE(obj, "visibleForm", false, setObj)}
                visible={obj.visibleForm}
                draggable={false}
            >
                <div className="flex flex-col px-3 pb-3 font1 gap-4">
                    <div className="flex flex-wrap gap-2">
                        {/* Renderizar dinámicamente los campos del formulario */}
                        {formFields.map((field, index) => (
                            <div
                                key={index}
                                className="flex flex-col"
                                style={{ width: `calc(100% / (${field.cols}) - .5rem)` }}
                            >
                                <div>{field.label}</div>
                                <div className="">
                                    {renderInput(field)}
                                </div>
                            </div>
                        ))}
                    </div>

                    {(RenderExtraComponent) && <RenderExtraComponent product={obj.dataForm} setProduct={setFormData} />}


                    <div className={`flex gap-2 justify-${obj.dataForm.id ? "between" : "end"}`}>
                        {obj.dataForm.id && <Button onClick={validDelete} className={`p-1 border-1 rounded border-rose-300 w-20 text-rose-600 justify-center`}>Eliminar<IoTrashBinOutline className="ml-2" /></Button>}
                        <Button onClick={validForm} className={`p-1 border-1 rounded border-emerald-400 text-emerald-500 w-20 justify-center`}>Guardar<FaCheck className="ml-2" /></Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}