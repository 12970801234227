import axios from "axios";

const token = localStorage.getItem("token");

export const fetchWithTokenHeader = async (url, method, body) => {
    try {
      let response = await fetch(`${url}`, {
        method,
        headers: {
          'authorization' : token
        },
        body
      });
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(" Error en consulta a backend ")
    }
  }

  export const AxiosWithoutHeader = async (url, method, body , contentType = 'application/json') =>{
    try {
        const response = await axios({
            method,
            url,
            data: body,
            headers: {
                'Content-Type': contentType
              }
          })
        return response;
    } catch (error) {
        console.error(error);
        throw new Error("Error en la peticion axios ")
    }
}
export const AxiosWithHeader = async (url, method, body , contentType = 'application/json') =>{
    try {
        const response = await axios({
            method,
            url,
            data: body,
            headers: {
                'Content-Type': contentType,
                'Authorization': token
              }
          })
        return response;
    } catch (error) {
        console.error(error);
        throw new Error("Error en la peticion axios ")
    }
}
function toJSON(file){
  return {
    name: file.name,
    type: file.type,
    size: file.size,
  };
}
export const AxiosWithHeaderFiles = async (url, body) =>{
  try {
      const response = await axios.post(url, body, {
        transformRequest: [function(data) {
          const variations = data.variaciones;
    
          for (const variation of variations) {
            variation.photos = Array.from(variation.photos).map((photo) => toJSON(photo));
          }
    
          return JSON.stringify(data);
        }],
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': token
        },
      })
      return response;
  } catch (error) {
      console.error(error);
      throw new Error("Error en la peticion axios ")
  }
}

// promises -> PUEDEN SER VARIOS ARREGLOS DE PROMESAS 
// data -> ARREGLO DE DATOS DE LAS PROMESAS QUIERO DEVOLVER ESTO...
export const debouncePromises = (promises, callback) => {
  const timer = setTimeout(() => {
    Promise.all(promises)
      .then((dataResponse) => {
        callback(dataResponse);
      })
      .catch((e) => {
        console.error('Error al obtener información', e);
      })
      .finally(() => {
        // Cualquier lógica que desees ejecutar después de obtener los datos
      });
  }, 500);

  return () => clearTimeout(timer);
};