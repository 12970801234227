import { useState } from 'react';
import icGSMX from "../../../../../assets/imgs/gelsoftmx/GELSOFTMX_HIDROGEL_A_OTRO_NIVEL.png"
 
const CartDialogTitleComponent = () => {

    const [state, setState] = useState({});
    return (
        <>
             <div className="flex flex-col gap-1">
                    <div className="flex self-center">
                        <img src={icGSMX} className="h-[3rem]"/>
                    </div>
                    <div className="flex justify-center pb-2 mt-[-1rem] ">
                        <div className="text-base font-semibold border-2 border-neutral-500 px-1 rounded bg-white">CHECKOUT</div>
                    </div>
                </div>
        </>
    );
}
 
export default CartDialogTitleComponent;