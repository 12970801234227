import { InputText } from "primereact/inputtext"
import { useContext, useState } from "react"
import { IoSearch, IoSend } from "react-icons/io5"
import AuthContext from "../../../../../AuthContext"

export const Tracking = () => {
    const authContext = useContext(AuthContext)
    const [componentData, setComponentData] = useState({
        loading: false,
        order: null,
        search: ''
    })
    const seach = () => {
        const input = document.getElementById('input-tracking')
        setComponentData({...componentData, loading: true})
        setTimeout(() => {
            setComponentData({...componentData, loading: false})
            authContext.toast.current.show({severity:"error", detail:"Orden no encontrada"})
        }, 500);
        input.blur()
    }
    return (
        <>
        
        <div className="flex flex-col px-3 pt-2 pb-4 bg-neutral-300 w-full">
                    <div className="flex justify-center w-full  font1 font-semibold text-[1.3rem]">👇 Aqui puedes rastrear tu orden</div>
                    <div className="flex border-2 border-neutral-600/60 rounded w-full bg-neutral-200 items-center">
                        <div className="flex px-2">
                            {componentData.loading?
                                <div className="loader-spinner1 bg-emerald-700" />:
                                <IoSearch size={26} className="text-neutral-600" />
                            }
                        </div>
                        <InputText id="input-tracking" className="px-2 py-1 w-full bg-neutral-200 focus:ring-0" 
                        name="search" value={componentData.search}
                        onChange={(e)=> setComponentData({...componentData, search: e.target.value})}
                        placeholder="Busca tu orden" />
                        <div className="flex px-2 text-neutral-700 hover:scale-110 hover:text-neutral-500 duration-300" onClick={() => seach() }>
                            <IoSend size={26} className="" />
                        </div>
                    </div>
                </div>
        </>
    )
}