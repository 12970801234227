import React, { useState, useEffect, useContext } from "react";
import ExcelJS from 'exceljs';
import { StringToMXN } from "../assets/utils/currency.utils.js";
import CreateOt from "../components/ot/CreateOt.js";
import TableData from "../components/utilities/Datatable.js";
import SearchTable from "../components/utilities/search/searchTable.js";
import DropdownMenu from "../components/utilities/dropdownMenu.js";
import ORDERS_CONSTANTS from "../constants/orders.constants.js";
import { EditInactiveIcon } from "../components/utilities/iconsProductsOptions.js";
import { PiPackageBold } from "react-icons/pi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoAlert } from "react-icons/io5";
import { FaGears } from "react-icons/fa6";



import "../assets/css/orders/orders.css"
import _, { orderBy } from "lodash";
import AuthContext from "../AuthContext.js";
import { BiWorld } from "react-icons/bi";
import { RequestLists } from "../components/registered/requests/RequestLists.js";
import { AxiosWithHeader, debouncePromises } from "../assets/utils/curl.utils.js";


function Store() {
  const authContext = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [dataModal, setDataModal] = useState([]);
  const [selectedOt, setSelectedOt] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [isLoading, setIsLoading] = useState(true);


  let [totalData, setTotalData] = useState([]); 
  const [componentData, setComponentData] = useState({
    loading: true,
    otData: {
      data: [],
      filtered: [],
      selectedOt: {}
    },
    search: "",
    visibleFormOt: false,
  })
  let timer


  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   setShowModal(dataModal.length > 0);
  // }, [dataModal]);


  const fetchData = async () => {
    try {
      setIsLoading(true)
      timer = debouncePromises([
        AxiosWithHeader(`${authContext.host}/ot/active`, "GET")
      ],([otData]) => {
        let data = otData.data
        data = orderBy(data, ['estado'],['asc']);
        
        setComponentData({...componentData,
          otData: {
            data,
            filtered:[],
            selectedOt: {}
          },
          loading: false
        })
        setIsLoading(false)
      })
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false)
    }
  };

  const getOtDetail = async (ot) => {
    try {
      const response = await fetch(`${authContext.host}/ot-detail/${ot.id}`);
      return await response.json();
    } catch (error) {
      console.error("Error fetching ot detail:", error);
    }
  };

  const exportToExcel = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Lista de órdenes de compra');

    worksheet.addRow(['Name', 'Variation', 'Qty', 'URL']);
    data.details.forEach((row) => {
      const product = row;
      row.variaciones.map( variation => {
        if( parseFloat(variation.cantidad) > 0 ){
          worksheet.addRow([
            product.nombre + ' / ' + variation.nombre, 
            variation.nombre_ch, 
            variation.cantidad, 
            product.url
          ]);
        }
      })
    });
    const fileName = data.decripcion || 'ot-' + data.id + '-' + data.description
    saveExcelFile(workbook, fileName);
  };

  const saveExcelFile = (workbook, fileName) => {
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }, 0);
    });
  };

  const modalOt = async (ot) => {
    setIsLoading(true);
    const otDetail = await getOtDetail(ot);
    const dataDetail = otDetail.map(row => {
      row.producto.cantidad = row.cantidad;
      return row.producto;
    });
    setSelectedOt(ot);
    setDataModal(dataDetail);
    setIsLoading(false);
  };

  const getEstatus = (id_status) => {
    const status = ORDERS_CONSTANTS.ESTATUS.find(v => v.id == id_status);
    return status ? status.nombre : "No disponible";
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const getGain = (row) => {
    let ganancia = 0;
        let rowData = totalData.find( r => r.ot.id == row.id );
        if(rowData && rowData.details){
          for( const productDetail of rowData.details){
            const precioSugerido = parseFloat(productDetail.product.precio_sugerido || 0);
            ganancia += ( productDetail.cantidad * (precioSugerido || 0) )
          }
          return ganancia.toFixed(2)
    }
    return ganancia
  }
  const handleRowSelect = ({ selectedRows }) => {
    let totalgain = 0;
    let totalCost = 0;
    for(const sr of selectedRows){
      // ganancia de row
      const g = parseFloat(getGain(sr))
      const porc = (g*100)/sr.totalFinal;
      console.log('gainedc **', [sr, porc, g])

      totalgain += g
      totalCost += sr.totalFinal
    }
    console.log('totalgain **', {
      totalGain: StringToMXN(totalgain),
      totalCost: StringToMXN(totalCost),
      porcGain: ((totalgain * 100) / totalCost).toFixed(2)
    } )
  }

  const columns = [
    {
      name: 'Estado',
      selector: row => getEstatus(row.estado)
    },
    {
      name: 'Descripcion',
      selector: row => row.decripcion
    },
    {
      name: 'Piezas',
      selector: row => row.totalPcs
    },
    {
      name: 'Total Yuan',
      selector: row => StringToMXN(row.totalYuan),
    },
    {
      name: 'Total MXN',
      selector: row => StringToMXN(row.totalMxn)
    },
    {
      name: 'Precio final',
      selector: row => StringToMXN(row.totalFinal || 0)
    },
    {
      name: 'Ganancia',
      selector: row => {
        
        let ganancia = 0;
        let rowData = totalData.find( r => r.ot.id == row.id );
        if(rowData && rowData.details){
          for( const productDetail of rowData.details){
            const precioSugerido = parseFloat(productDetail.product.precio_sugerido || 0);
            ganancia += ( productDetail.cantidad * (precioSugerido || 0) )
          }
          return StringToMXN(ganancia.toFixed(2))
        }
      }
        

    },
    {
      name: 'Mas',
      selector: row => row.totalMxn,
      cell: (row) => (
        <div className="dropdowm-menu">
          <DropdownMenu
            items={[
              {
                text: "Ver",
                icon: EditInactiveIcon,
                onClick: () => {
                  modalOt(row)
                },
              },
              {
                text: "Excel",
                icon: EditInactiveIcon,
                onClick: () => {
                  exportToExcel(row)
                },
              }
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={`col-span-${authContext.colSpan} flex flex-col justify-center`}>
        {showModal && (
          <CreateOt
            open={showModal}
            products={dataModal}
            setShowOtModal={setShowModal}
            selectedOt={selectedOt}
            setSelectedOt={setSelectedOt}
            isUpdate={true}
          />
        )}
        {/* CARDS OPTIONS */}
        <div className={`options-solicitudes flex my-3 gap-2 justify-center overflow-x-scroll mx-${authContext.isMobile ? 2: 4 }`}>
          <div className="border-solid border-2 rounded border-black px-2 py-0 min-w-24 flex flex-col items-center font1">
              <div><BiWorld size={35}/></div>
              <div>Todo</div>
          </div>
          <div className="border-solid border-2 rounded border-black px-2 py-0 min-w-24 flex flex-col items-center font1">
              <div><FaRegMoneyBillAlt size={35}/></div>
              <div>Pagado</div>
          </div>
          <div className="border-solid border-2 rounded border-black px-2 py-0 min-w-24 flex flex-col items-center font1">
              <div><IoAlert size={35}/></div>
              <div>Pendiente</div>
          </div>
          <div className="border-solid border-2 rounded border-black px-2 py-0 min-w-24 flex flex-col items-center font1">
              <div><FaGears  size={35}/></div>
              <div>Generado</div>
          </div>
        </div>
        {/* CARD TABLE */}
        <div className={`flex flex-col card p-2 mx-${authContext.isMobile ? 2 : 4}`}>
          {/* HEADER */}
          <div className={`flex justify-between my-2 ${authContext.isMobile ? "flex-col" : "" }`}>
              <div className="flex flex-col font1 ml-2 w-full">
                <span className="flex text-2xl items-end">
                  <div className="flex text-cyan-600 text-4xl text-bold">S</div>
                  <div>OLICITUDES</div>
                </span>
                <div>Aqui puedes visualizar las solicitudes asi como su contenido</div>
              </div>
              <div className="flex justify-end self-center w-full p-2">
                <SearchTable searchTerm={searchTerm} onSearchTermChange={handleSearchTermChange} isLoading={isLoading} />
              </div>
          </div>
          {/* TABLE */}
          <div className="table-store overflow-auto">
            <RequestLists requests={componentData.otData.data} exportToExcel={exportToExcel} />
          </div>
        </div>
        
    </div>
  );
}

export default Store;