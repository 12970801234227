import React, { useContext, useRef } from 'react';
import { IoIosAdd, IoIosImages } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import AuthContext from '../../AuthContext';

import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';


export function ProductForm({ product, setProduct }) {
    const fileUploadRef = useRef(0)
    const authContext = useContext(AuthContext)

    const handleInputChange = (key, value) => {
        if (key === 'imagenes') {
            value = value.split(',').map((url) => url.trim());
        }
        setProduct({ ...product, [key]: value });
    };

    const handleProductVariationChange = (e, index) => {
        const { name, value } = e.target;
        const updatedProduct = { ...product };
        updatedProduct.variaciones[index] = {
            ...updatedProduct.variaciones[index],
            [name]: value,
        };
        setProduct(updatedProduct);
    };

    const addProductVariation = () => {
        const updatedProduct = { ...product, variaciones: (product.variaciones) ? product.variaciones : [] };
        updatedProduct.variaciones.push({ nombre: '', nombre_ch: '', img: '' });
        setProduct(updatedProduct);
    };

    const removeProductVariation = (index) => {
        const updatedProduct = { ...product };
        updatedProduct.variaciones.splice(index, 1);
        setProduct(updatedProduct);
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        if (event.target.indexValue == -1) {
            const photos = Object.keys(files).map((fileIndex) => files[fileIndex])
            setProduct({ ...product, photos })
        } else {
            handleProductVariationChange({ target: { name: "photos", value: files } }, event.target.indexValue)
        }
    };

    const openFileUploadDialog = (index = -1) => {
        fileUploadRef.current.indexValue = index
        fileUploadRef.current.click();
    };

    const isFirstVariation = (product) => {

        return (!product.variaciones || (product?.variaciones && product?.variaciones.length == 0))
    }

    const isVisibleTo = () => {
        return (!product.id || product.photos)
    }

    return (
        <div className={`${authContext.isMobile ? 'overflow-x-scroll' : ''} `}>
            <div className="flex gap-1 flex-wrap flex-col">
                <input
                    ref={fileUploadRef}
                    type="file"
                    name="imagen"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    multiple
                />
                {/* OPCIONES */}
                <div className="row">
                    <div className="flex gap-1 items-center">
                        <div className="flex w-full gap-1">
                            <Checkbox
                                className='ch-border-2 ch-rounded'
                                inputId="webPage" name="webPage" value={product.webPage} onChange={(e) => handleInputChange(e.target.name, !e.target.value)} checked={product.webPage} />
                            <div>Available</ div>
                        </div>
                        <div className="flex w-full gap-1">
                            <Checkbox
                                className='ch-border-2 ch-rounded'
                                inputId="iscomming" name="isComming" value={product.isComming} onChange={(e) => handleInputChange(e.target.name, !e.target.value)} checked={product.isComming} />
                            <div>Por LLegar</ div>
                        </div>
                        <div className="flex w-full gap-1 items-center">
                            <Checkbox
                                className='ch-border-2 ch-rounded'
                                inputId="preventa" name="preventa" value={product.preventa} onChange={(e) => handleInputChange(e.target.name, !e.target.value)} checked={product.preventa} />
                            <div className='flex flex-col w-[5rem]'>
                                Preventa
                                {product.preventa &&
                                    <InputText className='pl-2 w-full bg-neutral-200/80 border-b-2 border-neutral-500 rounded ring-0'
                                        name='preventa_day' value={product.preventa_day} onChange={(e) => handleInputChange(e.target.name, e.target.value)} />
                                }
                            </ div>
                        </div>
                    </div>

                </div>

                {/* Variaciones */}
                <div className='mb-3 flex justify-between '>
                    <div className='flex items-center text-xl semibold'>Variaciones
                        {isVisibleTo() &&
                            <>
                                <div onClick={() => addProductVariation()} className="ml-1 cursor-pointer ease-out hover:scale-110 hover:ease-in duration-300  px-1 py-0 rounded-md text-sm p-2 hover:duration-300 hover:border-indigo-300"><IoIosAdd size={33} color='green' /></div>
                                <div onClick={() => openFileUploadDialog()}
                                    className="flex flex-col cursor-pointer ease-out duration-300  
                                px-1 py-0 rounded-md text-sm p-2 ">
                                    <div>{(product.photos) ? product.photos.length : 0} fotos</div>
                                    <IoIosImages size={28} color='gray' className='duration-300 ease-out hover:scale-110 hover:ease-in  hover:duration-300 hover:border-indigo-300' />
                                </div>
                            </>
                        }
                    </div>
                </ div>
                {isVisibleTo() ? (
                    <div className={`${isFirstVariation(product) ? "items-center " : ''} flex flex-col w-full overflow-x-scroll gap-1`}>
                        {product?.variaciones?.map((variation, index) => (
                            <div key={index} className="flex gap-2">
                                <div className="flex flex-col w-[6rem]">
                                    <div>Nombre ES </ div>
                                    <input type="text" name="nombre" value={variation.nombre} onChange={(e) => handleProductVariationChange(e, index)}
                                        className="border-gray-300 text-gray-900 rounded-md focus:ring-indigo-600 focus:border-indigo-600 px-2 py-1 " />
                                </div>
                                <div className="flex flex-col w-[6rem]">
                                    <div>Nombre CH </ div>
                                    <input type="text" name="nombre_ch" value={variation.nombre_ch} onChange={(e) => handleProductVariationChange(e, index)}
                                        className="border-gray-300 text-gray-900 rounded-md focus:ring-indigo-600 focus:border-indigo-600 px-2 py-1 " />
                                </div>
                                <div className="flex flex-col w-[5rem]">
                                    <div>Compra</ div>
                                    <input type="text" min={0} name="precio" value={variation.precio} onChange={(e) => handleProductVariationChange(e, index)}
                                        className="border-gray-300 text-gray-900 rounded-md focus:ring-indigo-600 focus:border-indigo-600 px-2 py-1 " />
                                </div>
                                <div className="flex flex-col w-[5rem]">
                                    <div>Venta</ div>
                                    <input type="number" min={0} name="precio_sugerido" value={variation.precio_sugerido} onChange={(e) => handleProductVariationChange(e, index)}
                                        className="border-gray-300 text-gray-900 rounded-md focus:ring-indigo-600 focus:border-indigo-600 px-2 py-1 " />
                                </div>
                                <div className="flex flex-col w-[5rem]">
                                    <div>Stock</ div>
                                    <input type="number" min={0} name="stock" value={variation.stock} onChange={(e) => handleProductVariationChange(e, index)}
                                        className="border-gray-300 text-gray-900 rounded-md focus:ring-indigo-600 focus:border-indigo-600 px-2 py-1 " />
                                </div>
                                <div className="flex flex-col ">
                                    <div>{(variation.photos && variation.photos.length > 0 ? variation.photos.length : "0 ")} Imágenes </ div>
                                    <div className="flex gap-2 justify-between ">
                                        <div onClick={() => openFileUploadDialog(index)} className="cursor-pointer ease-out hover:scale-110 hover:ease-in duration-300 border-1 border-gray-300 px-1 py-0 rounded-md text-sm p-2 hover:duration-300 hover:border-indigo-300"><IoIosImages size={33} /></div>

                                        <div onClick={() => removeProductVariation(index)}
                                            className="cursor-pointer border-1 border-gray-300 px-1 py-0 rounded-md text-sm p-2
                                        ease-out duration-300 hover:scale-110 hover:ease-in hover:duration-300 hover:border-indigo-300">
                                            <RiDeleteBin5Line size={33} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {isFirstVariation(product) &&
                            <div onClick={() => addProductVariation()}
                                style={{ width: "5rem" }}
                                className="flex border-1 border-gray-300 px-1 py-0 rounded-md text-sm p-2 cursor-pointer ease-out hover:scale-110 hover:ease-in duration-300">
                                <IoIosAdd size={30} />
                                <div className='mt-1 mr-2' >Nuevo</div>
                            </div>
                        }

                    </div>
                ) : (
                    <div className='flex gap-2'>
                        <div className='flex flex-col'>
                            <div>Nombre Variante</div>
                            <input
                                name="descripcion" id="descripcion" value={product.descripcion}
                                onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            />
                        </div>
                        <div >
                            <label
                                htmlFor="nombre_ch"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Nombre CH
                            </label>
                            <input
                                name="nombre_ch"
                                id="nombre_ch"
                                value={product.nombre_ch}
                                onChange={(e) => { handleInputChange(e.target.name, e.target.value) }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            />
                        </div>
                        <div >
                            <label
                                htmlFor="imagenes"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                {(product.imagenes && product.imagenes.length > 0 ? product.imagenes.length : "0 ")} Imagenes
                            </label>
                            <div onClick={() => openFileUploadDialog(0)} className="cursor-pointer ease-out hover:scale-110 hover:ease-in duration-300 border-1 border-gray-300 px-1 py-0 rounded-md text-sm p-2 hover:duration-300 hover:border-indigo-300"><IoIosImages size={33} /></div>

                        </div>
                    </ div>
                )}

            </div>

        </ div>
    );
};
