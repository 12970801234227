import { FaArrowRight } from "react-icons/fa"
import { LiaShippingFastSolid } from "react-icons/lia"

const CardTemplate = ({shipment, openModal}) => {
    return (
    <div className="card p-2" onClick={() => openModal(shipment)}>
        <div className="flex flex-col min-w-36">
            <div className="flex justify-between">
                <div className="flex flex-col">
                    <div>{shipment.shipmentName}</div>
                    <div className="flex">{(shipment.workOrders && shipment.workOrders.length > 0) ? shipment.workOrders.length : "0" } <FaArrowRight className="mx-1 mt-1" /> OT'S</div>
                </div>
                <LiaShippingFastSolid size={30} color={"gray"} />
            </div>
            <div className="flex justify-between mt-2" >
                <div className="flex flex-col">
                    <div>Salida</div>
                    <div>{(shipment.startDate) ? new Date(shipment.startDate).toLocaleDateString() : "--" }</div>
                </div>
                <div className="flex flex-col">
                    <div>Llegada</div>
                    <div>{(shipment.endDate) ? new Date(shipment.endDate).toLocaleDateString() : "--" }</div>
                </div>
            </div>
        </div>
    </div>
    )
}

export const ShipmentsList = ({ShipmentsList, openModal}) => {
    return (
        <div className="flex flex-wrap gap-2 justify-center">
            {ShipmentsList.map( (shipment) => <CardTemplate shipment={shipment} openModal={openModal} /> )}
        </div>
    )
}