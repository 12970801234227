
import React, { useState } from "react";
import { Paginator } from 'primereact/paginator';
import { Dropdown } from "primereact/dropdown";

export default function PRPaginator({pageSize, totalRecords= 0, setData}) {
    const [first, setFirst] = useState(0);

    const onPageChange = (event) => {
        setFirst(event.first);
        setData(event);
    };
    const template = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 font1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        Productos:{' '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} className="border-1 h-7 pl-1 font1" />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span className="font1" style={{ color: 'var(--text-color)', userSelect: 'none', width: '80px', textAlign: 'center' }}>
                    {options.first} - {options.last} de {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div >
            <Paginator className="h-10" template={template} first={first} rows={pageSize} totalRecords={totalRecords} onPageChange={onPageChange} />
        </div>
    );
}
        