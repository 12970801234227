export const LogoGelsoftMX = () => {
    return (
        <div className=" flex items-center">
          <div className="flex flex-col border-r-8 border-orange-500/90 pr-1 mr-1 font7" >
            <div className="text-neutral-100 text-4xl">GELSOFTMX</div>
          </div>
          <div className="flex flex-col font1" style={{fontSize:"1.1rem"}}>
            <div className="text-neutral-300 mr-1">Hidrogel</div>
            <div className="text-neutral-300 flex" style={{marginTop:"-.7rem"}}>a otro nivel</div>
          </div>
        </div>
    )
}