import { useEffect, useState } from 'react';
import {InputText} from 'primereact/inputtext'
const CheckoutFormComponent = () => {
    // const {} = this.props;
    const [state, setState] = useState({});
    useEffect(() => {
    },[])
    return (
        <>
             <div className="flex flex-col px-2 py-2 ">
                    <div className="flex px-2 pb-2 self-center">
                        <div className="text-base font-semibold border-2 border-neutral-500 px-1 rounded bg-white">DATOS DE CONTACTO</div>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        <div className="flex flex-col flex-wrap w-[48%]">
                            <InputText className="px-1 border-b-[3px] border-neutral-400" placeholder="Nombres" />
                        </div>
                        <div className="flex flex-col flex-wrap w-[48%]">
                            <InputText className="px-1 border-b-[3px] rounded border-neutral-400" placeholder="Apellidos" />
                        </div>
                        <div className="flex flex-col flex-wrap w-[48%]">
                            <InputText className="px-1 border-b-[3px] rounded border-neutral-400" placeholder="Correo electronico" />
                        </div>
                        <div className="flex flex-col flex-wrap w-[48%]">
                            <InputText className="px-1 border-b-[3px] rounded border-neutral-400" placeholder="Numero telefonico" />
                        </div>
                    </div>
                    <div className="flex px-2 pt-2 self-center">
                        <div className="text-base font-semibold border-2 border-neutral-500 px-1 rounded bg-white">DATOS DE ENVÍO</div>
                    </div>
                    <div className="flex flex-wrap gap-2">

                        <div className="flex flex-col flex-wrap w-[48%]">
                            <InputText className="px-1 border-b-[3px] rounded border-neutral-400" placeholder="Calle y numero" />
                        </div>
                        <div className="flex flex-col flex-wrap w-[48%]">
                            <InputText className="px-1 border-b-[3px] rounded border-neutral-400" placeholder="Colonia" />
                        </div>
                        <div className="flex flex-col flex-wrap w-[48%]">
                            <InputText className="px-1 border-b-[3px] rounded border-neutral-400" placeholder="Codigo postal" />
                        </div>
                    </div>
                </div>
        </>
    );
}
 
export default CheckoutFormComponent;