export function SET_VALUE_TO_STATE(object, key, value , set = null) {
    const newObject = Object.assign({}, object);
    newObject[key] = value;
    if(set){ set(newObject) }
    return newObject;
  }

  export function FN_PAGINATE_ARRAY(array, pageSize, currentPage) {
    const startIndex = (currentPage) * pageSize;
    const endIndex = Math.min(array.length, startIndex + pageSize);
    return array.slice(startIndex, endIndex);
  }

  export const PRICE_FORMAT = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
