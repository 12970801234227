import { useState } from 'react';
import { LiaShippingFastSolid } from "react-icons/lia";
import { StringToMXN } from '../../../../../assets/utils/currency.utils';
 
const ShippingOptionsComponent = (props) => {
    const {
        cartDataComponent,
        setCartDataComponent
    } = props;
    const [state, setState] = useState({});

    return (
        <>
             <div className="flex flex-wrap gap-1 justify-between w-full">
                        <div className="flex flex-col">
                            <div className="flex items-center">
                                {StringToMXN(cartDataComponent.priceShipping)} MXN
                            </div>
                            <div className="flex">
                            {   [
                                {name: "Paqueteria", icon: LiaShippingFastSolid, size: 15},
                            ].map((it,ind) =>{
                                return <div className={`flex flex-col border-2 border-neutral-500 rounded p-1 items-center 
                                hover:border-neutral-900 duration-500 ease-out cursor-pointer 
                                ${ind==cartDataComponent.shipping_type ? "bg-black text-white": ""}`} 
                                onClick={() => setCartDataComponent({...cartDataComponent, shipping_type: ind}) }>
                                <div className="flex"><it.icon size={it.size} /></div>
                                <div className="flex">{it.name}</div>
                            </div>
                            })

                            }
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex items-center">
                                Solo CDMX $0.00
                            </div>
                            <div className="flex gap-1">
                            {   [
                                {name: "Tasqueña", icon: LiaShippingFastSolid, size: 15},
                                {name: "Copilco", icon: LiaShippingFastSolid, size: 15},
                                {name: "Iztapalapa", icon: LiaShippingFastSolid, size: 15},
                            ].map((it,ind) =>{
                                return <div className={`flex flex-col border-2 border-neutral-500 rounded p-1 items-center 
                                hover:border-neutral-900 duration-500 ease-out cursor-pointer 
                                ${(ind + 1)==cartDataComponent.shipping_type ? "bg-black text-white": ""}`} 
                                onClick={() => setCartDataComponent({...cartDataComponent, shipping_type: ind + 1}) }>
                                <div className="flex"><it.icon size={it.size} /></div>
                                <div className="flex">{it.name}</div>
                            </div>
                            })

                            }
                            </div>
                        </div>
                    </div>
        </>
    );
}
 
export default ShippingOptionsComponent;