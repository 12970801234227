import { useEffect } from "react";
import PRODUCTS_CONSTANTS, { PRODUCTS_CTEGORIA } from "../../../../constants/products.constants";

// Define el array de objetos para los campos del formulario
export const ReturnProductsFormFields = (obj , isMobile) => {
  useEffect( () => {
    console.log('obj cambio !', obj)
  }, [obj])
    return [
    { cols:(isMobile) ? "2" : "2", label: 'Producto', type: 'text', name: 'nombre' , value: obj.dataForm.nombre },
    { cols:(isMobile) ? "2" : "2", label: 'Precio', type: 'number', name: 'precio', min:0 , value: obj.dataForm.precio },
    // { cols:(isMobile) ? "2" : "2", label: 'Categoria', type: 'dropdown', options: PRODUCTS_CONSTANTS.CATEGORIA , name: 'id_categoria' , value: obj.dataForm.id_categoria },
    // { cols:(isMobile) ? "2" : "2", label: 'Sub Categoría', type: 'dropdown', options: PRODUCTS_CONSTANTS.SUBCATEGORIA ,name: 'id_subcategoria' , value: obj.dataForm.id_subcategoria },
    { cols:"1", label: 'Categoría', type: 'treeselect', options: PRODUCTS_CTEGORIA ,name: 'id_categoria' , value: obj.dataForm.id_categoria },
    { cols:(isMobile) ? "2" : "3", label: 'Sensible?', type: 'dropdown',options: PRODUCTS_CONSTANTS.SENSITIVE, name: 'sensitive_shipping' , value: obj.dataForm.sensitive_shipping },
    { cols:(isMobile) ? "2" : "3", label: 'URL Producto', type: 'text', name: 'producto' , value: obj.dataForm.producto },
    { cols:(isMobile) ? "2" : "3", label: 'Precio Seguerido', type: 'number',min:0, name: 'precio_sugerido' , value: obj.dataForm.precio_sugerido },
    { cols:"1", label: 'Descripción', type: 'textarea', rows:5 ,name: 'text_detail' , value: obj.dataForm.text_detail },
    
  ];
}