import { useNavigate } from "react-router-dom";
import PublicBody from "./PublicBody";
import { PublicFooter } from "./PublicFooter";
import PublicHeader from "./PublicHeader";
import { useEffect, useState } from "react";

function PublicLayout() {
  // const navigate = useNavigate();
  // const [isFirstRender, setIsFirstRender] = useState(true);
  // useEffect(() => {
  //   if (isFirstRender) {
  //     navigate('/admin/'); // Redirect to /admin/ on initial render
  //     setIsFirstRender(false);
  //   }
  // }, [isFirstRender, navigate]);
  return (
    <>
      <div itemScope>
        {/* <meta name="HandheldFriendly" content="true" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="GelsoftMX App"
        />
        <title>GelsoftMX</title> 
      </div>

      <div className="flex flex-col">
          <PublicHeader />
          <PublicBody />
          {/* <PublicFooter /> */}
      </div>
    </>
  );
}

export default PublicLayout;
