import React, { useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/private/Layout";
import NoPageFound from "./pages/NoPageFound";
import AuthContext from "./AuthContext";
import ProtectedWrapper from "./ProtectedWrapper";
import { useEffect, useState } from "react";

import { Toast } from 'primereact/toast';
      

import "./output.css";
import "react-datepicker/dist/react-datepicker.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./assets/css/utilities/spinners.css"
import { useWindowSize } from "./hooks/Responsive";
import PublicLayout from "./components/layout/public/PublicLayout";
import Login from './pages/Login';
import Ecommercecontext from "./EcommerceContext";


const App = () => {
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(true);
  const { isMobile } = useWindowSize()
  const toast = useRef(null);

  const [ecommerceContextData, setEcommerceContextData] = useState({
    user:{} , cart:[]
  })

  useEffect(() => {
    const userdata = localStorage.getItem("user");
    if (userdata && userdata !== "undefined") {
      try {
        const user = JSON.parse(userdata)
        setUser( user );
      } catch (error) {
        console.error('user token error')
      }
    }
  }, []);

  useEffect(() => {
    setLoader(false)
  }, [user]);

  const signin = (newUser, callback) => {
    setUser(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
    callback()
  };

  const signout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };
  const host = process.env.REACT_APP_API_URL;

  document.oncontextmenu = function(){return false}
  document.ondragstart = function(){return false}
  document.onselectstart = function(){return false}
  document.onmousedown = function(event) {
    if (!event.target.tagName.toLowerCase().match(/^(input|textarea)$/)) {
        return false;
    }
};

  let value = { user, signin, signout , isMobile, colSpan: isMobile ? 12 : 11 , host, toast };
  let ecommerceContext = { ecommerceContextData, setEcommerceContextData, toast}
  if (loader)
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="font1 text-2xl">LOADING...</div>
      </div>
    );

  return (
    <AuthContext.Provider value={value}>
    <Ecommercecontext.Provider value={ecommerceContext}>

      <Toast ref={toast} />
      <BrowserRouter>
        <Routes>
          {/* ADMIN */}
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/*"  element={
              <ProtectedWrapper>
                <Layout />
              </ProtectedWrapper>
            }
          />
          {/* ECOMMERCE */}
          <Route path="/*" element= {<PublicLayout />} /> 
          <Route path="*" element={<NoPageFound />} />

        </Routes>
      </BrowserRouter>
    </Ecommercecontext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
