// import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";

import logoImg from "../assets/imgs/logo-bg.png"
import { Button } from "primereact/button";
import { HttpStatusCode } from "axios";

function Login() {
  const apiHost = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const loginUser = (e) => {
    // Cannot send empty data
    if (form.username === "" || form.password === "") {
      authContext.toast.current.show({severity:"danger", summary:"Error", detail:"Campos requeridos"})
      // alert("To login user, enter details to proceed...");
    } else {
      setLoading(true)
      fetch(`${apiHost}/auth`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(form),
      })
        .then((response) => {

          if (!response.ok) {
            throw new Error('Error en la solicitud');
          }
          return response.json();
        })
        .then( data => {
          const authResponse = data;
          if(authResponse.status != HttpStatusCode.Unauthorized){
            localStorage.setItem("user", JSON.stringify(authResponse.user));
            localStorage.setItem("token", authResponse.acces_token);
            authContext.signin(authResponse, () => {
              navigate("/admin");
            });
          }else{
            authContext.toast.current.show({severity:"error", summary:"Error", detail:"Credenciales no válidas"})
          }
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          authContext.toast.current.show({severity:"error", summary:"Error", detail:"Credenciales no válidas"})
          
        });
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  };

  
  return (
    <>
      <div className="flex flex-col w-full h-screen items-center justify-center font-sans bg-neutral-500" >
        
        <form className=" space-y-2" onSubmit={handleSubmit}>
              {/* <input type="hidden" name="remember" defaultValue="true" /> */}
              <div className="flex justify-center">
                <img src={logoImg} width={150}/>
              </div>
              <div className="flex flex-col justify-center text-white">
                <div className="text-center font1 text-4xl">Low Cost</div>
                <div className="text-center font1 text-sm">By Uriel García</div>
              </div>
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Username
                  </label>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    autoComplete="text"
                    required
                    className="relative block w-full rounded-t-md border-0 py-1.5 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Username"
                    value={form.username}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="relative block w-full rounded-b-md border-0 py-1.5 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Password"
                    value={form.password}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div>
                <Button
                  type="submit"
                  className="
                  flex w-full justify-center rounded-md bg-black 
                  py-1 px-3 text-white 
                  hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 
                  focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  loading={loading}
                  onClick={loginUser}
                >
                  <span className="font1">
                  ACCESO
                  </span>
                </Button>
              </div>
        </form>
      </div>
    </>
  );
}

export default Login;
