import { Menu } from '@headlessui/react';

export default function DropdownMenuItem({ text, icon: Icon, onClick }) {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={onClick}
          className={`${
            active ? 'bg-violet-500 text-white' : 'text-gray-900'
          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
        >
          {active ? <Icon className="mr-2 h-5 w-5" /> : <Icon className="mr-2 h-5 w-5" />}
          {text}
        </button>
      )}
    </Menu.Item>
  );
}