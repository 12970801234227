

export const PromotionProductCardComponent = ({product , photosrc}) => {
    return (
        <div className="flex flex-col rounded-lg max-w-[8rem] min-w-[8rem] ">
                            <div className="flex max-h-[5rem] min-h-[5rem] items-center">
                                <img src={photosrc} alt="" />
                            </div>
                            <div className="flex flex-col text-base font1 ">
                                <div className="font-semibold">{product.name}</div>
                                <div className="bg-black text-neutral-200 px-1">$ {product.price} MXN</div>
                            </div>
                        </div>
        
    )
}
